import type { MachineState } from "external/vendor/types";

export function getStateButtonLabels(state: MachineState): {
  nextButtonLabel: string;
  backButtonLabel: string;
} {
  const stateName = `${state.machine?.id}.${state.toStrings()[0]}`;

  if (!state.meta[stateName]) {
    return { nextButtonLabel: "Continue", backButtonLabel: "Back" };
  }

  const { nextButtonLabel, backButtonLabel } = state.meta[stateName];

  return {
    nextButtonLabel: nextButtonLabel?.(state.context) || "Continue",
    backButtonLabel: backButtonLabel?.(state.context) || "Back",
  };
}
