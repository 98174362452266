import { defineMessages } from "react-intl";

export const messages = defineMessages({
  contactHeading: {
    id: "olb.contact.contactHeading",
    defaultMessage: "Enter contact info",
    description: "The header for the contact section of the form",
  },
  serviceAddressHeading: {
    id: "olb.contact.serviceAddressHeading",
    defaultMessage: "Enter service address",
    description: "The header for the service address section of the form",
  },
  phoneNumberFieldPlaceholder: {
    id: "olb.contact.phoneNumberFieldPlaceholder",
    defaultMessage: "Phone number",
    description: "The placeholder message for the phone number input",
  },
  additionalDetailsHeading: {
    id: "olb.contact.additionalDetailsHeading",
    defaultMessage: "Enter additional details",
    description: "The header for the additional details section of the form",
  },
  additionalDetailsMainDescription: {
    id: "olb.contact.additionalDetailsMainDescription",
    defaultMessage:
      "Share any additional details that will help prepare for this appointment (e.g. access instructions or other notes)",
    description:
      "The bottom description for the multiline input of additional details",
  },
  additionalDetailsPlaceholder: {
    id: "olb.contact.additionalDetailsPlaceholder",
    defaultMessage: "Message",
    description:
      "The placeholder value for the multiline input of additional details",
  },
  emailConsentDescription: {
    id: "olb.contact.emailConsentDescription",
    defaultMessage:
      "By providing your email, you consent to receiving marketing emails and promotions. You can unsubscribe at any time.",
    description: "The description for the consent details for the email input",
  },
  phoneConsentDescription: {
    id: "olb.contact.phoneConsentDescription",
    defaultMessage:
      "By providing your phone number, you agree to receive text messages (SMS). Message and data rates may apply. Message frequency varies.",
    description: "The description for the consent details for the phone input",
  },
});
