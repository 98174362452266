import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import { Content } from "@jobber/components/Content";
import { messages } from "./messages";
import styles from "./styles.module.css";

export function ServiceAreaSkeleton() {
  return (
    <Content>
      <div className={styles.centered}>
        <Heading level={3}>{messages.heading.defaultMessage}</Heading>
      </div>
      <div className={styles.items}>
        <Glimmer shape="rectangle" />
      </div>
      <div className={styles.items}>
        <Glimmer shape="rectangle" size="larger" />
      </div>
    </Content>
  );
}
