export const buildLocation = (
  latitude: number | undefined,
  longitude: number | undefined,
) => {
  if (latitude && longitude) {
    return {
      latitude,
      longitude,
    };
  }
};
