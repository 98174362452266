import React from "react";
import type {
  SocialLink,
  socialNames,
} from "external/vendor/components/FormFooter/FormFooter";
import { format } from "external/vendor/utils/format";
import styles from "./styles.module.css";
import { Social } from "./components/Social";

export interface SocialLinksProps {
  socialLinks: SocialLink[];
}

export function Socials({ socialLinks }: SocialLinksProps) {
  const sortedSocials = sortSocialLinks(socialLinks);

  return (
    <div className={styles.icons}>
      {sortedSocials &&
        sortedSocials.map(socialInfo => (
          <Social
            key={socialInfo.sortOrder}
            name={socialInfo.name}
            url={socialInfo.url}
          />
        ))}
    </div>
  );
}
interface SocialWithSort {
  url: string;
  name: socialNames;
  sortOrder: number;
}

const socialPlatformSortOrder = {
  facebook: 0,
  twitter: 1,
  instagram: 2,
  yelp: 3,
  angieslist: 4,
  google: 5,
};

function sortSocialLinks(socialLinks?: SocialLink[]) {
  if (socialLinks) {
    const socialArr: SocialWithSort[] = [];

    for (const link of socialLinks) {
      socialArr.push({
        url: format.url(link.url),
        name: link.socialPlatform,
        sortOrder: socialPlatformSortOrder[link.socialPlatform],
      });
    }
    socialArr.sort((a, b) => a.sortOrder - b.sortOrder);
    return socialArr;
  }
}
