import React, { useContext } from "react";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { InputValidation } from "@jobber/components/InputValidation";
import classnames from "classnames";
import type { Appointment } from "external/vendor/types";
import { format } from "external/vendor/utils/format";
import { ArrivalWindowContext } from "external/vendor/context";
import { minutesToDuration } from "utilities/time/minutesToDuration";
import styles from "./styles.module.css";
import { messages } from "./messages";
import { SelfServeBookingDaySlotsPickerSkeleton } from "./SelfServeBookingDaySlotsPicker.skeleton";

interface InternalDaySlotsProps {
  selected: boolean;
  appointment: Appointment;
  arrivalWindowDuration: number;
  arrivalWindowCentered: boolean;
  setSelectedTimeSlot(appointment: Appointment): void;
}

export interface SelfServeBookingDaySlotsPickerProps {
  duration: number;
  selectedDate: Date | undefined;
  appointments: Appointment[];
  showNoneSelectedError: boolean;
  selectedAppointment: Appointment | undefined;
  onSelectAppointment: (appointment: Appointment) => void;
}

export function FormattedApproximateDuration({
  durationMinutes,
}: {
  durationMinutes: number;
}) {
  const duration = minutesToDuration(durationMinutes);

  return (
    <div className={styles.durationContainer}>
      <Icon name={"timer"} color={"grey"} />
      <div className={styles.durationText}>
        <Text>Estimated appointment duration: {duration}</Text>
      </div>
    </div>
  );
}

function InternalDaySlots({
  selected,
  appointment,
  setSelectedTimeSlot,
  arrivalWindowDuration,
  arrivalWindowCentered,
}: InternalDaySlotsProps) {
  const startTime = new Date(appointment.startTime);
  const endTime = new Date(appointment.endTime);

  const formattedTimeRange = format.timeRange(
    startTime,
    endTime,
    arrivalWindowDuration,
    arrivalWindowCentered,
  );

  function setAsSelected() {
    setSelectedTimeSlot(appointment);
  }

  const buttonClass = classnames(
    styles.cardBorder,
    styles.cardBorderInteractive,
    { [styles.cardBorderSelected]: selected },
  );

  return (
    <button
      className={buttonClass}
      onClick={setAsSelected}
      role="radio"
      aria-checked={selected}
      aria-label={formattedTimeRange}
      data-testid="appointment-time-slot"
    >
      <Text size="base">{formattedTimeRange}</Text>
      <div className={styles.checkmark}>
        {selected && <Icon name="checkmark" color={"green"} />}
      </div>
    </button>
  );
}

function NoAppointments({ formattedDate }: { formattedDate: string }) {
  return (
    <div className={styles.noAppointmentsContainer}>
      <Banner dismissible={false} type={"notice"}>
        <Text size="small">
          No appointments are available on {formattedDate}
        </Text>
      </Banner>
    </div>
  );
}

export function SelfServeBookingDaySlotsPicker({
  duration,
  selectedDate,
  appointments,
  showNoneSelectedError,
  selectedAppointment,
  onSelectAppointment,
}: SelfServeBookingDaySlotsPickerProps) {
  const formattedDate = selectedDate
    ? selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : "";
  const { arrivalWindowDuration, arrivalWindowCentered } =
    useContext(ArrivalWindowContext);

  return (
    <>
      <div className={styles.headerContainer}>
        <Heading level={3}>
          {arrivalWindowDuration ? "Select an arrival time" : "Select a time"}
        </Heading>
        {formattedDate && (
          <div className={styles.dateContainer}>
            <Text>{formattedDate}</Text>
          </div>
        )}
      </div>
      {formattedDate ? (
        <>
          {arrivalWindowDuration !== 0 && (
            <FormattedApproximateDuration durationMinutes={duration} />
          )}

          {showNoneSelectedError && (
            <InputValidation
              message={messages.noAppointmentSelectedError.message}
            />
          )}
          {appointments.length === 0 ? (
            <NoAppointments formattedDate={formattedDate} />
          ) : (
            <div role="radiogroup" aria-label="Appointment Time">
              {appointments.map(appointment => {
                return (
                  <InternalDaySlots
                    key={appointment.id}
                    selected={selectedAppointment?.id === appointment.id}
                    appointment={appointment}
                    setSelectedTimeSlot={onSelectAppointment}
                    arrivalWindowDuration={arrivalWindowDuration}
                    arrivalWindowCentered={arrivalWindowCentered}
                  />
                );
              })}
            </div>
          )}
        </>
      ) : (
        <SelfServeBookingDaySlotsPickerSkeleton />
      )}
    </>
  );
}
