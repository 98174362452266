import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import type { Appointment } from "external/vendor/types";
import type { LocationInput } from "~/utilities/API/graphqlExternal";
import {
  SelfServeBookingDatePicker,
  SelfServeBookingDaySlotsPicker,
} from "./components";
import { useAppointmentTimes } from "./hooks";
import styles from "./styles.module.css";

export interface AppointmentSelectionProps {
  uuid: string;
  duration: number;
  appointment: Appointment | undefined;
  location?: LocationInput;
  showNoneSelectedError: boolean;
  setAppointment(appointment: Appointment): void;
  clearAppointment(): void;
}

export function AppointmentSelection({
  uuid,
  duration,
  appointment,
  location,
  showNoneSelectedError,
  setAppointment,
  clearAppointment,
}: AppointmentSelectionProps) {
  const startDate = appointment?.startTime
    ? new Date(appointment?.startTime)
    : undefined;

  const {
    loading,
    error,
    selectedDate,
    setSelectedDate,
    appointments,
    highlightedDates,
    setSelectedMonth,
  } = useAppointmentTimes(uuid, duration, startDate, location);

  function changeDate(date: Date) {
    setSelectedDate(date);
    clearAppointment();
  }

  return (
    <>
      {error && (
        <Banner type="error" dismissible={false}>
          {error.message}
        </Banner>
      )}
      <Content>
        <div className={styles.container}>
          <div className={styles.datePicker}>
            <Heading level={3}>Select a date</Heading>
            <SelfServeBookingDatePicker
              inline
              loading={loading}
              selected={selectedDate}
              onChange={changeDate}
              highlightDates={highlightedDates}
              onMonthChange={setSelectedMonth}
            />
          </div>
          <div className={styles.timePicker}>
            <SelfServeBookingDaySlotsPicker
              duration={duration}
              appointments={appointments}
              selectedDate={selectedDate}
              showNoneSelectedError={showNoneSelectedError}
              selectedAppointment={appointment}
              onSelectAppointment={setAppointment}
            />
          </div>
        </div>
      </Content>
    </>
  );
}
