import React, { useCallback, useState } from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { InputEmail } from "@jobber/components/InputEmail";
import { InputText } from "@jobber/components/InputText";
import { Grid } from "@jobber/components/Grid";
import classNames from "classnames";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import type { FileUpload } from "@jobber/components/InputFile";
import { InputAddress } from "components/InputAddress/InputAddress";
import { useBookingMachineContext } from "external/vendor/context";
import type { MachineContext } from "external/vendor/booking.machine";
import { ImageUpload } from "~/components/ImageUpload/ImageUpload";
import styles from "./styles.module.css";
import { PhoneNumberWrapper } from "./PhoneNumberWrapper";
import { messages } from "./messages";

type ContactFields = keyof MachineContext["contactInfo"];

export function Contact() {
  const { formatMessage } = useIntl();
  const [current, send] = useBookingMachineContext();

  const [invalidPhone, setInvalidPhone] = useState(
    current.context.userError === "phoneNumberError",
  );

  const onFieldChanged = useCallback(
    (fieldName: ContactFields) => (newValue: string) =>
      send({
        type: "CONTACT_CHANGED",
        contact: { [fieldName]: newValue },
      }),
    [send],
  );
  const shareRow: { readonly [key: string]: number } = {
    xs: 12,
    md: 6,
  };
  const handleUpdateImages = useCallback(
    (files: FileUpload[]) => {
      send({
        type: "ATTACHMENTS_CHANGED",
        attachments: files,
      });
    },
    [send],
  );

  return (
    <Content>
      <Heading level={3}>{formatMessage(messages.contactHeading)}</Heading>
      <div>
        <Grid gap={false}>
          <Grid.Cell size={shareRow}>
            <div className={styles.inputGroupWithValidationsFix}>
              <InputText
                placeholder="First name"
                name="firstName"
                value={current.context.contactInfo.firstName}
                onChange={onFieldChanged("firstName")}
                validations={{
                  maxLength: 255,
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
              />
            </div>
          </Grid.Cell>
          <Grid.Cell size={shareRow}>
            <div
              className={classNames(
                styles.inputGroupWithValidationsFix,
                styles.lastName,
              )}
            >
              <InputText
                placeholder="Last name"
                name="lastName"
                value={current.context.contactInfo.lastName}
                onChange={onFieldChanged("lastName")}
                validations={{
                  maxLength: 255,
                  required: {
                    value: true,
                    message: "Required field",
                  },
                }}
              />
            </div>
          </Grid.Cell>
        </Grid>
        <div
          className={classNames(
            styles.inputGroupWithValidationsFix,
            styles.removeTopBorder,
          )}
        >
          <InputText
            placeholder="Company name (if applicable)"
            name="companyName"
            value={current.context.contactInfo.companyName}
            onChange={onFieldChanged("companyName")}
            validations={{
              maxLength: 255,
            }}
          />
        </div>
      </div>

      <div>
        <InputEmail
          placeholder="Email"
          name="email"
          value={current.context.contactInfo.email}
          onChange={onFieldChanged("email")}
          description={formatMessage(messages.emailConsentDescription)}
          validations={{
            maxLength: 255,
            required: {
              value: true,
              message: "Required field",
            },
          }}
        />
      </div>

      <div>
        <PhoneNumberWrapper
          invalidPhone={invalidPhone}
          setInvalidPhone={setInvalidPhone}
          phoneNumber={current.context.contactInfo.phoneNumber}
          onFieldChanged={onFieldChanged("phoneNumber")}
        />
      </div>

      <Heading level={3}>
        {formatMessage(messages.serviceAddressHeading)}
      </Heading>
      <InputAddress
        name="serviceAddress-"
        address={{
          ...current.context.serviceAddress,
          zipOrPostalCode: current.context.serviceAddress.postalCode,
        }}
        onChange={newAddress =>
          send({
            type: "ADDRESS_CHANGED",
            address: {
              ...newAddress,
              province: newAddress.stateOrProvince,
              postalCode: newAddress.zipOrPostalCode,
            },
          })
        }
      />

      <Heading level={3}>
        {formatMessage(messages.additionalDetailsHeading)}
      </Heading>
      <Text>{formatMessage(messages.additionalDetailsMainDescription)}</Text>
      <InputText
        placeholder={formatMessage(messages.additionalDetailsPlaceholder)}
        defaultValue={""}
        multiline
        value={current.context.jobDescription}
        onChange={newDescription =>
          send({
            type: "JOB_DESCRIPTION_CHANGED",
            jobDescription: newDescription.toString(),
          })
        }
      />
      <ImageUpload
        onChange={handleUpdateImages}
        files={current.context.attachments}
      />
    </Content>
  );
}
