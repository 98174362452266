import React from "react";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import type { OrganizationLeadRouteFragment } from "~/utilities/API/graphqlExternal";
import { messages } from "./messages";
import styles from "./styles.module.css";

interface ChooseBusinessProps {
  businesses: Array<OrganizationLeadRouteFragment>;
  onBack: () => void;
}

export function ChooseBusiness({ businesses, onBack }: ChooseBusinessProps) {
  const businessCards = businesses.map(business => (
    <BusinessClickableCard key={business.id} business={business} />
  ));

  return (
    <>
      <Heading level={3}>{messages.heading.defaultMessage}</Heading>
      {businessCards}
      <Button
        icon="arrowLeft"
        variation="subtle"
        fullWidth={true}
        label={messages.backButtonLabel.defaultMessage}
        onClick={onBack}
      />
    </>
  );
}

function BusinessClickableCard({
  business,
}: {
  business: OrganizationLeadRouteFragment;
}) {
  return (
    <a href={business.url} className={styles.clickableBusinessCard}>
      <Heading level={5}>{business.name}</Heading>
      <Icon name="arrowRight" />
    </a>
  );
}
