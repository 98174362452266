import React, { useMemo } from "react";
import { useBookingMachineContext } from "external/vendor/context";
import { buildLocation } from "external/vendor/utils/buildLocation";
import type { Appointment, SelectedOffering } from "external/vendor/types";
import { AppointmentSelection } from "./AppointmentSelection";

interface AppointmentSelectionLoaderProps {
  uuid: string;
}

export function AppointmentSelectionLoader({
  uuid,
}: AppointmentSelectionLoaderProps) {
  const [current, send] = useBookingMachineContext();
  const { context } = current;
  const { latitude, longitude } = context.serviceAddress;

  const duration = useMemo(
    () =>
      context.selectedOfferings.reduce(
        (durationTotal: number, offering: SelectedOffering) =>
          durationTotal + offering.durationMinutes * offering.quantity,
        0,
      ),
    [context.selectedOfferings],
  );

  const setAppointment = (appointment: Appointment) =>
    send({ type: "SELECTED_APPOINTMENT", appointment });
  const clearAppointment = () => send({ type: "CLEARED_APPOINTMENT" });

  return (
    <AppointmentSelection
      uuid={uuid}
      duration={duration}
      appointment={current.context.appointment}
      location={buildLocation(latitude, longitude)}
      setAppointment={setAppointment}
      clearAppointment={clearAppointment}
      showNoneSelectedError={current.matches("Appointment.noneSelectedError")}
    />
  );
}
