//
// Analytics interface for tracking unauthenticated users in Online Booking.
// This might work to track other unauthed users, but be sure to check a few
// things before reusing this.
//   - Are you definietely only tracking unauthenticated/anonymous users?
//   - Is only one event getting sent to Amplitude after configuring things?
//     - This library tries to make sure that it's only initialized once, but
//       be sure to check that's true.
//
import * as amplitude from "@amplitude/analytics-browser";

export function initialize({
  amplitudeApiKey,
  accountId,
}: {
  amplitudeApiKey: string;
  accountId: string;
}) {
  if (window.JobberAnalytics == null) {
    // Prevent unintentional reuse of samesite data set by cookies in ClientHub
    amplitude.init(amplitudeApiKey, undefined, { cookieSameSite: "None" });
    amplitude.setGroup("accountId", accountId);

    window.JobberAnalytics = {
      track(eventName: string, eventProperties?: EventProperties) {
        amplitude.track(eventName, eventProperties);
      },
    };
  }
}
