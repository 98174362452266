import React from "react";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Divider } from "@jobber/components/Divider";
import { Emphasis } from "@jobber/components/Emphasis";
import Lottie from "lottie-react";
import { useBookingMachineContext } from "external/vendor/context";
import { ServiceList } from "components/ServiceList";
import { messages } from "./messages";
import styles from "./styles.module.css";
import checkAnimation from "./checkAnimation.json";
import { AppointmentSummary } from "./components/AppointmentSummary";

export function Confirmation() {
  const [current] = useBookingMachineContext();

  const { currency } = current.context.vendor;
  const confirmedServices = current.context.selectedOfferings.map(offering => {
    return {
      ...offering,
      price: offering.basePrice,
    };
  });

  const lottieStyle = {
    height: 60,
  };
  return (
    <Content>
      <Lottie animationData={checkAnimation} loop={false} style={lottieStyle} />

      <div className={styles.headerContainer}>
        <Heading level={3}>{messages.confirmationHeader.message}</Heading>

        <Text>
          {messages.confirmationSubHeader.message}
          <Emphasis variation="bold">
            {current.context.contactInfo.email}
          </Emphasis>
        </Text>
      </div>

      <Divider size="large" />

      {current.context.appointment && (
        <AppointmentSummary appointment={current.context.appointment} />
      )}

      <ServiceList
        services={confirmedServices}
        currency={currency}
        showHeader={false}
      />
    </Content>
  );
}
