import { addMinutes } from "date-fns";

interface TimeRangeProps {
  startTime: Date;
  endTime: Date;
  arrivalWindowDuration: number;
  arrivalWindowCentered: boolean;
}

interface TimeRange {
  start: Date;
  end: Date;
}

function getTimeRange({
  startTime,
  endTime,
  arrivalWindowDuration,
  arrivalWindowCentered,
}: TimeRangeProps): TimeRange {
  const showArrivalWindow = arrivalWindowDuration !== 0;
  const arrivalWindowStartTime = getArrivalWindowStartTime(
    arrivalWindowCentered,
    arrivalWindowDuration,
    startTime,
  );

  const rangeStartTime = showArrivalWindow ? arrivalWindowStartTime : startTime;
  const rangeEndTime = showArrivalWindow
    ? addMinutes(arrivalWindowStartTime, arrivalWindowDuration)
    : endTime;

  return { start: rangeStartTime, end: rangeEndTime };
}

export function timeRange(
  startTime: Date,
  endTime: Date,
  arrivalWindowDuration: number,
  arrivalWindowCentered: boolean,
) {
  const range = getTimeRange({
    startTime,
    endTime,
    arrivalWindowDuration,
    arrivalWindowCentered,
  });

  const formattedStartTime = get12hFormat(range.start);
  const formattedEndString = get12hFormat(range.end);

  return `${formattedStartTime} – ${formattedEndString}`;
}

function get12hFormat(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  // to show 12 PM/AM instead of 0 PM/AM
  const hours12 = hours % 12 || 12;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours12}:${minutesStr} ${ampm}`;
}

function getArrivalWindowStartTime(
  windowCentered: boolean,
  windowDuration: number,
  startTime: Date,
) {
  const arrivalWindowStart = addMinutes(startTime, -windowDuration / 2);

  return windowCentered ? arrivalWindowStart : startTime;
}
