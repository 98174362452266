import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Glimmer } from "@jobber/components/Glimmer";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import styles from "./styles.module.css";

export function OfferingsSelectionSkeleton() {
  const { formatMessage } = useIntl();
  return (
    <Content>
      <Heading level={3}>{formatMessage(messages.heading)}</Heading>
      <Content>
        {Array.from(Array(5).keys()).map(key => (
          <Card key={key}>
            <Content>
              <div className={styles.cardWrapperSkeleton}>
                <Content>
                  <Glimmer shape="rectangleShorter" />
                  <Glimmer size="small" />
                  <div className={styles.bottomRowSkeleton}>
                    <div className={styles.priceDurationSkeleton}>
                      <Glimmer size="small" />
                    </div>
                    <div className={styles.addButtonSkeleton}>
                      <Glimmer size="larger" />
                    </div>
                  </div>
                </Content>
              </div>
            </Content>
          </Card>
        ))}
      </Content>
    </Content>
  );
}
