import React, { useEffect } from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { InputValidation } from "@jobber/components/InputValidation";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { useBookingMachineContext } from "external/vendor/context";
import type { Offering } from "external/vendor/types";
import type { SelectedService } from "external/vendor/App";
import { OfferingsSelectionList } from "./components/OfferingsSelectionList";
import { messages } from "./messages";

interface OfferingsSelectionProps {
  defaultService?: SelectedService;
}
export function OfferingsSelection({
  defaultService,
}: OfferingsSelectionProps): JSX.Element {
  const [current, send] = useBookingMachineContext();
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (defaultService?.matched && defaultService?.fingerprint) {
      const localOffering = current.context.vendor.offerings.find(offering => {
        return offering.fingerprint === defaultService.fingerprint;
      });
      localOffering && handleSelection(localOffering, "SELECTED_OFFERING");
    }
  }, [current.context.vendor.offerings]);
  function handleSelection(
    offering: Offering,
    type: "SELECTED_OFFERING" | "DESELECTED_OFFERING",
  ): void {
    send({ type, offering });
  }

  return (
    <Content>
      <Heading level={3}>{formatMessage(messages.heading)}</Heading>

      {defaultService?.matched === false && (
        <Banner type="notice">
          {formatMessage(messages.defaultOfferingExpiredError)}
        </Banner>
      )}
      {current.matches("Offering.errorState") && (
        <InputValidation message={formatMessage(messages.noSelectionsError)} />
      )}

      <OfferingsSelectionList
        currency={current.context.vendor.currency}
        offerings={current.context.vendor.offerings}
        selectedOfferings={current.context.selectedOfferings}
        handleOfferingsClick={handleSelection}
      />
    </Content>
  );
}
