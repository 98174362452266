import { Icon } from "@jobber/components/Icon";
import React from "react";
import type { socialNames } from "external/vendor/components/FormFooter/FormFooter";
import styles from "./styles.module.css";

interface SocialProps {
  url: string;
  name: socialNames;
}

export function Social({ name, url }: SocialProps) {
  return (
    <a
      className={styles.iconLink}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.iconWrapper}>
        <Icon name={name} size="small" color="greyBlue" />
      </div>
    </a>
  );
}
