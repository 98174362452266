import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { useInterpret } from "@xstate/react";
import { format } from "external/vendor/utils/format";
import { buildLocation } from "external/vendor/utils/buildLocation";
import { VENDOR_QUERY } from "external/vendor/graphql";
import { Amplitude as Analytics } from "~/utilities/analytics/Amplitude";
import { SERVICE_AREA_QUERY } from "external/vendor/views/ServiceArea/graphql";
import { type MachineContext, machine } from "./booking.machine";
import { useCreateBooking, useLocationGeocode } from "./hooks";

// We want the browser back and forward buttons to work as expected on this page
// which means that we need to keep the browser history in sync with the state.
// So when we press one of our in-page navigation buttons we need to add an
// extra call to update the browser history since it otherwise won't know that
// the page content has changed.
//
// Clicking the browser back button:
//  - The browser back button modifies the history
//  - Modifying the history emits a `POP` history event
//  - The `browserHistoryListener` service receives the `POP` event
//  - The `browserHistoryListener` service sends a `PREVIOUS_STEP` event to the machine
//  - The machine transitions to the previous state
//
// Pressing the React back button:
//   - The React back button sends the "PREVIOUS_STEP_AND_UPDATE_HISTORY" event to the machine
//   - The machine runs the "popFromHistory" action which modifies the history by calling `history.goBack()`
//   - Modifying the history emits a `POP` history event
//   - The `browserHistoryListener` service receives the `POP` history action
//   - The `browserHistoryListener` service sends a `PREVIOUS_STEP` event to the machine
//   - The machine transitions to the previous page
//
// TODO: Add diagram

// "Action Creator" that encapsulates making an action that will track a page view
// It would be nice to be able to just say `actions: ["track"]`, but actions only
// get `context` and `event` and those don't have the state name, so we can't
// convert the state to page name value we want to send to Amplitude we have to
// curry it.
function trackPageView(pageName: string) {
  return () => {
    Analytics.TRACK_EVENT("Viewed Page", { name: pageName });
  };
}
function trackOnlineBookingError(errorType: string) {
  return () => {
    Analytics.TRACK_EVENT("Online Booking Error", {
      name: errorType,
    });
  };
}

export const useBookingStateMachine = (
  uuid: string,
  google = window.google,
  trackingSuborigin?: string,
) => {
  const history = useHistory();
  const { createBooking } = useCreateBooking();
  // Using apollo client because we don't need this hook causing rerenders
  // when the query finishes, instead the state machine itself will cause re-renders when state changes
  const apolloClient = useApolloClient();
  const { geocode } = useLocationGeocode(google);

  // The machine definition can be enhanced before it's used to create an active
  // service. In this case we need add the history actions in a context where
  // we can use a hook which isn't something that can be done staticly in the
  // base machine definition.
  return useInterpret(machine, {
    actions: {
      pushToHistory: () => {
        history.goForward();
      },
      popFromHistory: () => {
        history.goBack();
      },
      viewedServiceAreaEntry: trackPageView("Service Area Entry Page"),
      viewedServiceSelection: trackPageView("Service Selection Page"),
      viewedIntakeQuestions: trackPageView("Intake Question Page"),
      viewedAppointmentSelection: trackPageView("Appointment Selection Page"),
      viewedReviewAndConfirm: trackPageView("Review & Confirm Page"),
      viewedConfirmation: trackPageView("Confirmation Page"),
      appointmentTimeUnavailableError: trackOnlineBookingError(
        "appointment_time_unavailable",
      ),
      serviceUnavailableError: trackOnlineBookingError("service_unavailable"),
    },
    services: {
      fetchVendorData: () => {
        return apolloClient.query({
          query: VENDOR_QUERY,
          variables: { id: uuid },
        });
      },
      checkServiceArea: async (context: MachineContext) => {
        const { postalCode } = context.serviceAddress;
        const { countryCode: country } = context.vendor;

        const { results } = await geocode({
          componentRestrictions: {
            country,
            postalCode,
          },
        });

        return apolloClient.query({
          query: SERVICE_AREA_QUERY,
          variables: {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            vendorId: uuid,
          },
        });
      },
      geocodeAddress: async (context: MachineContext) => {
        const { serviceAddress } = context;
        const address = format.address.full(serviceAddress);
        const { results } = await geocode({ address });
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        return {
          latitude,
          longitude,
        };
      },
      refetchVendor: () => {
        return apolloClient.query({
          query: VENDOR_QUERY,
          variables: { id: uuid },
          fetchPolicy: "network-only",
        });
      },
      // Where does this function get called and where is the structure defined?
      browserHistoryListener: () => cb => {
        // create history states
        history.push("", -1); // back state
        history.push("", 0); // main state
        history.push("", 1); // forward state
        history.go(-1); // start in main state

        return history.listen(event => {
          if (event.state) {
            if (event.state === 1) {
              cb("NEXT_STEP");
            } else if (event.state === -1) {
              cb("PREVIOUS_STEP");
            }
            history.go(-event.state);
          }
          return null;
        });
      },
      createBooking: async (context: MachineContext) => {
        const {
          vendor,
          contactInfo,
          selectedOfferings,
          serviceAddress,
          appointment,
          jobDescription,
          attachments,
        } = context;
        const { firstName, lastName, email, phoneNumber, companyName } =
          contactInfo;
        const {
          street1,
          street2,
          city,
          postalCode,
          province,
          country,
          latitude,
          longitude,
        } = serviceAddress;

        return createBooking({
          variables: {
            vendorId: vendor.id,
            input: {
              offerings: selectedOfferings.map(offering => ({
                id: offering.id,
                fingerprint: offering.fingerprint,
                quantity: offering.quantity,
              })),
              availabilitySlotId: appointment?.id,
              street1,
              street2,
              city,
              province,
              postalCode,
              country,
              firstName,
              lastName,
              companyName,
              email,
              phoneNumber,
              instructions: jobDescription,
              location: buildLocation(latitude, longitude),
              attachments: attachments.map(attachment => ({
                id: attachment.key,
              })),
              trackingSuborigin,
            },
          },
        });
      },
    },
  });
};
