import React from "react";
// DO NOT COPY: Allowing an exception here for specific styling Typography.
// Header needs to be less bold than Heading provides
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { HeaderSkeleton } from "external/organization/components/Header/Header.skeleton";
import { useSetPageTitle } from "jobber/settings/users/hooks/useSetPageTitle";
import styles from "./styles.module.css";

interface HeaderProps {
  companyName: string | undefined;
  logoUrl: string | undefined;
  loading: boolean;
}

interface SetTitleProps {
  companyName: string;
}

function SetTitle({ companyName }: SetTitleProps) {
  useSetPageTitle(companyName);
  return <></>;
}

export function Header({
  companyName,
  logoUrl,
  loading,
}: HeaderProps): JSX.Element {
  const hasLogoAndName = logoUrl && companyName;

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerElement}>
        {loading ? (
          <HeaderSkeleton />
        ) : (
          <>
            {!loading && companyName && <SetTitle companyName={companyName} />}
            {logoUrl && (
              <img
                src={logoUrl}
                className={styles.logo}
                alt={companyName + " logo"}
              />
            )}
            {(!logoUrl || hasLogoAndName) && (
              <div className={styles.companyName}>
                <Typography
                  element="h3"
                  size="larger"
                  fontWeight="bold"
                  textColor="heading"
                >
                  {companyName}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
