import { gql } from "@apollo/client";

export const AVAILABILITY_SLOTS_QUERY = gql`
  query AvailabilitySlots(
    $vendorId: EncodedId!
    $input: AvailabilitySlotsInput!
  ) {
    availabilitySlots(vendorId: $vendorId, input: $input) {
      id
      duration
      startTime
      endTime
    }
  }
`;

const OFFERINGS_FRAGMENT = gql`
  fragment offeringsFragment on OfferingConnection {
    nodes {
      basePrice
      description
      fingerprint
      durationMinutes
      id
      name
      quantityRange {
        minQuantity
        maxQuantity
      }
    }
  }
`;

export const VENDOR_QUERY = gql`
  query vendor($id: EncodedId!) {
    vendor(id: $id) {
      id
      name
      logoUrl
      showCompanyName
      email
      calendarFirstDay
      confirmationMessage
      termsAndConditionsUrl
      acceptingBookings
      arrivalWindowDuration
      arrivalWindowCenteredOnStartTime
      countryCode
      currency
      phone
      websiteUrl
      locationRequired
      serviceAreasEnabled
      offerings {
        ...offeringsFragment
      }
      address {
        city
        country
        postalCode
        province
        street1
        street2
      }
      socialLinks {
        nodes {
          socialPlatform
          url
        }
      }
      rwgConversionEndpoint
      rwgPartnerId
    }
  }

  ${OFFERINGS_FRAGMENT}
`;
