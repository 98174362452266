import React, { type ReactNode } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

interface APIProviderProps {
  uri: string;
  children?: ReactNode;
}
export function APIProvider({ uri, children }: APIProviderProps) {
  const APIClient = new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={APIClient}>{children}</ApolloProvider>;
}
