import React from "react";
import { useFormContext } from "react-hook-form";
import { Button, type ButtonProps } from "@jobber/components/Button";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import { Glimmer } from "@jobber/components/Glimmer";
import { useBookingMachineContext } from "external/vendor/context";
import { TermsAndConditions } from "external/vendor/components/TermsAndConditions";
import { getStateButtonLabels } from "./stateButtonLabels";
import styles from "./styles.module.css";

interface ActionBarProps {
  onNavigate?(): void;
}

export function ActionBar({ onNavigate }: ActionBarProps) {
  const [current] = useBookingMachineContext();

  const { ref, inView } = useInView({
    threshold: 1,
    // small offset to help with alignment of button
    // when switching between static and fixed positioning
    rootMargin: "5px",
  });

  const hasAction =
    current.can("PREVIOUS_STEP") ||
    current.can("NEXT_STEP") ||
    current.tags.has("PREVIOUS_BUTTON_DISABLED") ||
    current.tags.has("NEXT_BUTTON_LOADING");
  const offscreen = !inView;
  const fixActionBar = offscreen && hasAction;

  const actionButtons = (
    <>
      <div className={styles.backButton}>
        <BackAction onNavigate={onNavigate} />
      </div>
      <NextAction onNavigate={onNavigate} />
    </>
  );
  const actionBarClasses = classNames(
    {
      [styles.fixed]: fixActionBar,
    },
    styles.actionBar,
  );
  return (
    <>
      <div
        className={classNames(
          { [styles.spaceForActionBar]: hasAction },
          styles.actionBarContainer,
        )}
      >
        <div data-testid="actionBar" className={actionBarClasses}>
          {fixActionBar ? (
            <div className={styles.actionBarBackground}>
              {offscreen && current.matches("Review") && (
                <div className={styles.termsConditionsWrapper}>
                  <TermsAndConditions />
                </div>
              )}
              <div className={styles.content}> {actionButtons}</div>
            </div>
          ) : (
            actionButtons
          )}
        </div>
      </div>
      {/* We check for the visibility of an item below the action bar so that the
          button is at least the distance of the padding above the bottom of the
          screen before switching from fixed to static positioning. */}
      <div ref={ref} className={styles.visibilityChecker} />
    </>
  );
}

type NextActionProps = Pick<ActionBarProps, "onNavigate">;
export function NextAction({ onNavigate }: NextActionProps) {
  const [current, send] = useBookingMachineContext();
  const { trigger } = useFormContext();
  const handleOnNext = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trigger();
    send("NEXT_STEP_AND_UPDATE_HISTORY");
    onNavigate?.();
  };

  if (
    current.can("NEXT_STEP") ||
    current.tags.has("NEXT_BUTTON_LOADING") ||
    current.tags.has("LOADING")
  ) {
    return (
      <div className={styles.nextButton}>
        {current.tags.has("LOADING") ? (
          <div className={styles.buttonSkeleton}>
            <Button label="" fullWidth={true} disabled={true} />
          </div>
        ) : (
          <Button
            label={getStateButtonLabels(current).nextButtonLabel}
            type="primary"
            loading={current.tags.has("NEXT_BUTTON_LOADING")}
            fullWidth={true}
            onClick={handleOnNext}
          />
        )}
      </div>
    );
  }
  return null;
}

type BackActionProps = Pick<ActionBarProps, "onNavigate"> & {
  iconOnly?: boolean;
};

export function BackAction({ onNavigate, iconOnly = false }: BackActionProps) {
  const [current, send] = useBookingMachineContext();
  const handleOnBack = () => {
    send("PREVIOUS_STEP_AND_UPDATE_HISTORY");
    onNavigate?.();
  };

  const label = getStateButtonLabels(current).backButtonLabel;
  const buttonProps: ButtonProps = iconOnly
    ? {
        type: "tertiary",
        ariaLabel: label,
        icon: "arrowLeft",
      }
    : {
        type: "primary",
        label,
      };

  if (
    current.can("PREVIOUS_STEP") ||
    current.tags.has("PREVIOUS_BUTTON_DISABLED")
  ) {
    return current.tags.has("LOADING") ? (
      <Glimmer />
    ) : (
      <Button
        {...buttonProps}
        variation="subtle"
        disabled={current.tags.has("PREVIOUS_BUTTON_DISABLED")}
        onClick={handleOnBack}
      />
    );
  }
  return null;
}
