import React, { useContext } from "react";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Divider } from "@jobber/components/Divider";
import type { Appointment } from "external/vendor/types";
import { minutesToDuration } from "utilities/time/minutesToDuration";
import { format } from "external/vendor/utils/format";
import { ArrivalWindowContext } from "external/vendor/context";
import styles from "./styles.module.css";

export function AppointmentSummary({
  appointment,
}: {
  appointment: Appointment;
}) {
  const { arrivalWindowDuration, arrivalWindowCentered } =
    useContext(ArrivalWindowContext);

  return (
    <Content>
      <div className={styles.dateContainer}>
        <Icon name="calendar" />

        <Text variation="default">
          {appointmentDateFormat(appointment.startTime)}
        </Text>
      </div>

      <div className={styles.timeDurationIconContainer}>
        <Icon name="timer" color="greyBlue" />

        <div className={styles.timeDurationContainer}>
          <AppointmentTime
            appointment={appointment}
            arrivalWindowDuration={arrivalWindowDuration}
            arrivalWindowCentered={arrivalWindowCentered}
          />

          {arrivalWindowDuration !== 0 && (
            <Text variation="default">
              Estimated duration: {minutesToDuration(appointment.duration)}
            </Text>
          )}
        </div>
      </div>
      <Divider size="large" />
    </Content>
  );
}

function AppointmentTime({
  appointment,
  arrivalWindowDuration,
  arrivalWindowCentered,
}: {
  appointment: Appointment;
  arrivalWindowDuration: number;
  arrivalWindowCentered: boolean;
}) {
  const startTime = new Date(appointment.startTime);
  const endTime = new Date(appointment.endTime);

  const formattedTimeRange = format.timeRange(
    startTime,
    endTime,
    arrivalWindowDuration,
    arrivalWindowCentered,
  );

  const showArrivalWindow = arrivalWindowDuration !== 0;
  const timeRangeString = showArrivalWindow
    ? `Arriving between ${formattedTimeRange}`
    : formattedTimeRange;
  return (
    <Content spacing="small">
      <Text variation="default">{timeRangeString}</Text>
    </Content>
  );
}

function appointmentDateFormat(start: string) {
  return new Date(start).toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}
