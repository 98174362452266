import React from "react";
import { Button } from "@jobber/components/Button";
import { Text } from "@jobber/components/Text";
import { AnimatedSwitcher } from "@jobber/components/AnimatedSwitcher";
import styles from "./styles.module.css";

interface QuantityPickerProps {
  readonly min: number;
  readonly max: number;
  readonly value: number;
  onChange(val: number): void;
  onRemove(): void;
}

const DEFAULT_UNIT = 1;

export function QuantityPicker({
  min,
  max,
  value,
  onChange,
  onRemove,
}: QuantityPickerProps) {
  return (
    // this is a legit usecase to prevent deselecting the parent without disabling accessibility
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={styles.container}
      onClick={e => e.stopPropagation()}
      data-testid="container"
    >
      <AnimatedSwitcher
        switched={value > min}
        initialChild={
          <Button
            icon="trash"
            type="secondary"
            ariaLabel="remove"
            onClick={onRemove}
          />
        }
        switchTo={
          <Button
            icon="minus2"
            type="secondary"
            ariaLabel="decrement"
            onClick={() => onChange(value - DEFAULT_UNIT)}
          />
        }
      />
      <Text align="center">{value}</Text>
      <Button
        icon="plus2"
        type="secondary"
        ariaLabel="increment"
        onClick={() => onChange(value + DEFAULT_UNIT)}
        disabled={max <= value}
      />
    </div>
  );
}
