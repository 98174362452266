import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Banner } from "@jobber/components/Banner";
import { Heading } from "@jobber/components/Heading";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { InputText, type InputTextRef } from "@jobber/components/InputText";
import { Button } from "@jobber/components/Button";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Spinner } from "@jobber/components/Spinner";
import { InputValidation } from "@jobber/components/InputValidation";
import type {
  OrganizationDataQuery,
  OrganizationLeadRouteFragment,
  OrganizationLeadRoutesQuery,
} from "~/utilities/API/graphqlExternal";
import {
  ORGANIZATION_LEAD_ROUTES_QUERY,
  ORGANIZATION_QUERY,
} from "external/organization/hooks/OrganizationDetails.graphql";
import { friendlyPhoneFormat } from "utilities/format/phone";
import { messages } from "./messages";
import styles from "./styles.module.css";
import { ChooseBusiness, Header } from "..";

interface RoutingFormProps {
  id: string;
}

function ErrorBanner() {
  return (
    <div className={styles.errorBanner}>
      <Banner type="error">{messages.errorBanner.defaultMessage}</Banner>
    </div>
  );
}

export function RoutingForm({ id }: RoutingFormProps): JSX.Element {
  const { loading, error, data } = useQuery<OrganizationDataQuery>(
    ORGANIZATION_QUERY,
    {
      variables: { organizationId: id },
    },
  );
  const [geoCode, setGeoCode] = useState<string>("");

  const [matches, setMatches] = useState<
    Array<OrganizationLeadRouteFragment> | undefined
  >(undefined);

  return (
    <div className={styles.container}>
      <Header
        companyName={data?.organization.name}
        logoUrl={data?.organization.logoUrl}
        loading={loading}
      />
      <div className={styles.centered}>
        <div className={styles.content}>
          <Card>
            <Content>
              {error && <ErrorBanner />}
              {(!matches || matches.length < 1) && (
                <LeadRouting
                  organizationId={id}
                  geoCode={geoCode}
                  setGeoCode={setGeoCode}
                  setBusinessResults={setMatches}
                  phone={data?.organization.phone}
                  email={data?.organization.email}
                  countryCode={data?.organization.countryCode}
                />
              )}
              {matches && matches.length > 1 && (
                <ChooseBusiness
                  businesses={matches}
                  onBack={() => setMatches(undefined)}
                />
              )}
              {matches && matches.length == 1 && (
                <Redirect url={matches[0].url} />
              )}
            </Content>
          </Card>
        </div>
      </div>
    </div>
  );
}

interface LeadRoutingProps {
  organizationId: string;
  geoCode: string;
  setGeoCode: Dispatch<SetStateAction<string>>;
  setBusinessResults: Dispatch<
    SetStateAction<Array<OrganizationLeadRouteFragment> | undefined>
  >;
  phone: string | undefined;
  email: string | undefined;
  countryCode: string | undefined;
}
function LeadRouting({
  organizationId,
  geoCode,
  setGeoCode,
  setBusinessResults,
  phone,
  email,
  countryCode,
}: LeadRoutingProps) {
  const [getBusinesses, { error, data }] =
    useLazyQuery<OrganizationLeadRoutesQuery>(ORGANIZATION_LEAD_ROUTES_QUERY);
  const inputRef = useRef<InputTextRef>(null);
  const geocodeCountryStyle = (() => {
    switch (countryCode) {
      case "CA":
        return messages.postalCodeLabelPostalCode.defaultMessage;
      case "US":
        return messages.postalCodeLabelZipCode.defaultMessage;
      default:
        return messages.postalCodeLabelDefault.defaultMessage;
    }
  })();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {error && <ErrorBanner />}
      <Heading level={3}>{messages.heading.defaultMessage}</Heading>
      <Text>{`Enter your ${geocodeCountryStyle} to get started`}</Text>
      <div>
        <InputText
          prefix={{ icon: "address" }}
          placeholder={
            geocodeCountryStyle.charAt(0).toUpperCase() +
            geocodeCountryStyle.slice(1)
          }
          value={geoCode}
          onChange={(userCode: string) => {
            setGeoCode(userCode);
          }}
          invalid={
            data?.organization?.leadRoutes?.nodes &&
            data?.organization?.leadRoutes?.nodes.length == 0
          }
          ref={inputRef}
          validations={{
            required: {
              value: true,
              message: `Enter a valid ${geocodeCountryStyle} to continue.`,
            },
          }}
        />
        {data?.organization?.leadRoutes?.nodes &&
          data?.organization?.leadRoutes?.nodes.length == 0 && (
            <div className={styles.validation}>
              <InputValidation message={getNoResultsMessage()} />
            </div>
          )}
      </div>
      <div className={styles.button}>
        <Button
          label={messages.button.defaultMessage}
          onClick={submitGeoCode}
        />
      </div>
    </>
  );

  function submitGeoCode() {
    if (geoCode.length > 0) {
      void getBusinesses({
        variables: { organizationId: organizationId, geoCode: geoCode },
      }).then(result => {
        if (result.data?.organization?.leadRoutes) {
          setBusinessResults(result.data.organization.leadRoutes.nodes);
        }
      });
    }
  }

  function getNoResultsMessage(): string {
    if (phone && email) {
      return `Unavailable for this area. Please contact us at ${formatPhone(
        phone,
      )} or ${email} for assistance.`;
    }
    if (phone) {
      return `We currently don’t service this area. For any questions, please contact us at ${formatPhone(
        phone,
      )}.`;
    }
    if (email) {
      return `We currently don’t service this area. For any questions, please contact us at ${email}`;
    }

    return "We currently don’t service this area. Please contact us for assistance.";
  }
}

function formatPhone(phone: string): string {
  try {
    const e164Number = friendlyPhoneFormat(phone);
    return e164Number;
  } catch (_error) {
    return phone;
  }
}

function Redirect({ url }: { url: string }) {
  window.location.assign(url);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spinner />
    </div>
  );
}
