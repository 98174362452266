export const messages = {
  expandDescription: {
    message: "See more",
    description:
      "Prompt on the offering description to see the full description",
  },
  addButtonLabel: {
    message: "Add",
    description: "The label on the button before selection",
  },
  addedButtonLabel: {
    message: "Added",
    description: "The label on the button after selection",
  },
  removeButtonLabel: {
    message: "Remove",
    description:
      "The label that is read out by screen readers when the offering is added",
  },
  offeringAddedAnnouncement: {
    message: "Added",
    description:
      "The message that is announced by screen readers when the offering is added",
  },
  offeringRemovedAnnouncement: {
    message: "Removed",
    description:
      "The message that is announced by screen readers when the offering is removed",
  },
};
