import React from "react";
import { Text } from "@jobber/components/Text";
import logo from "@images/powered_by_jobber_white.svg";
import styles from "./styles.module.css";

export function SecondaryContent() {
  return (
    <div className={styles.secondaryContent}>
      <div className={styles.poweredByJobberBox}>
        <Text>Powered By</Text>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://getjobber.com/"
        >
          <img
            className={styles.jobberImg}
            src={logo}
            alt="Powered by Jobber"
          />
        </a>
      </div>
      <Text>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://getjobber.com/terms-of-service/"
        >
          Terms of Service
        </a>
      </Text>
    </div>
  );
}
