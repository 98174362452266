import React from "react";
import { Text } from "@jobber/components/Text";
import { useBookingMachineContext } from "external/vendor/context";
import { getStateButtonLabels } from "external/vendor/components/ActionBar/stateButtonLabels";
import { format } from "external/vendor/utils/format";
import { messages } from "./messages";

export function TermsAndConditions() {
  const [current] = useBookingMachineContext();
  const termsAndConditionsUrl = current.context.vendor.termsAndConditionsUrl;
  if (!termsAndConditionsUrl) {
    return null;
  }

  const buttonLabel = getStateButtonLabels(current).nextButtonLabel;

  return (
    <div>
      <Text size="small" variation="subdued">
        {messages.termsAndConditionsDisclaimer.message(buttonLabel)}{" "}
        <a
          target="_blank"
          href={format.url(termsAndConditionsUrl)}
          rel="noreferrer"
        >
          {messages.termsAndConditions.message}
        </a>
      </Text>
    </div>
  );
}
