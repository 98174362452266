export const messages = {
  heading: {
    defaultMessage: "Select a location",
    description: "Heading of the card",
  },
  backButtonLabel: {
    defaultMessage: "Back",
    description: "Label for the back button",
  },
};
