import { createContext, useContext } from "react";
import type { InterpreterFrom } from "xstate";
import { useActor } from "@xstate/react";
import type { machine } from "external/vendor/booking.machine";

export const BookingMachineContext = createContext({
  bookingMachine: {} as InterpreterFrom<typeof machine>,
});

export function useBookingMachineContext() {
  const bookingService = useContext(BookingMachineContext);
  return useActor(bookingService.bookingMachine);
}
