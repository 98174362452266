import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { AVAILABILITY_SLOTS_QUERY } from "external/vendor/graphql";
import type {
  AvailabilitySlot,
  AvailabilitySlotsQuery,
  LocationInput,
} from "~/utilities/API/graphqlExternal";
import type { Appointment } from "external/vendor/types";

const MAX_MONTHS_TO_SEARCH = 2;

export function useAppointmentTimes(
  vendorId: string,
  duration: number,
  activeDate?: Date,
  location?: LocationInput,
) {
  const [initialMonthsSearched, setInitialMonthsSearched] = useState(1);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    activeDate,
  );
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    selectedDate ?? new Date(),
  );
  const [appointmentsByDate, setAppointmentsByDate] = useState<
    Record<string, Appointment[]>
  >({});

  const selectedMonthStart = useMemo(
    () =>
      new Date(
        selectedMonth.getFullYear(),
        selectedMonth.getMonth(),
        1,
        0,
        0,
        0,
      ),
    [selectedMonth],
  );
  const selectedMonthEnd = useMemo(
    () =>
      new Date(
        selectedMonth.getFullYear(),
        selectedMonth.getMonth() + 1,
        0,
        23,
        59,
        59,
      ),
    [selectedMonth],
  );

  const { loading, error } = useQuery<AvailabilitySlotsQuery>(
    AVAILABILITY_SLOTS_QUERY,
    {
      fetchPolicy: "no-cache",
      variables: {
        vendorId,
        input: {
          duration: duration,
          startTime: selectedMonthStart,
          endTime: selectedMonthEnd,
          location,
        },
      },
      onCompleted: data => {
        if (data?.availabilitySlots?.[0]) {
          mapAvailabilitySlotsToDate(data.availabilitySlots);

          if (selectedDate === undefined) {
            setSelectedDate(new Date(data.availabilitySlots[0].startTime));
          }
        } else if (selectedDate === undefined) {
          searchNextMonth();
        }
      },
    },
  );

  const highlightedDates = useMemo(
    () =>
      Object.keys(appointmentsByDate)
        .map(date => new Date(appointmentsByDate[date][0].startTime))
        .filter(date => date >= selectedMonthStart && date <= selectedMonthEnd),
    [appointmentsByDate, selectedMonthStart, selectedMonthEnd],
  );

  const appointments = selectedDate
    ? appointmentsByDate[dateToHashKey(selectedDate)] ?? []
    : [];

  return {
    loading: loading,
    error,
    selectedDate,
    highlightedDates,
    appointments,
    setSelectedDate,
    setSelectedMonth,
  };

  function mapAvailabilitySlotsToDate(slots: AvailabilitySlot[]) {
    const dateHash: Record<string, Appointment[]> = {};
    slots.forEach(appointment => {
      const dateKey = dateToHashKey(appointment.startTime);
      dateHash[dateKey] = dateHash[dateKey] ?? [];
      dateHash[dateKey].push(appointment);
    });
    setAppointmentsByDate(oldHash => ({ ...oldHash, ...dateHash }));
  }

  function searchNextMonth() {
    if (initialMonthsSearched < MAX_MONTHS_TO_SEARCH) {
      setInitialMonthsSearched(initialMonthsSearched + 1);
      setSelectedMonth(
        new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 1),
      );
    } else {
      setSelectedDate(new Date());
    }
  }
}

function dateToHashKey(date: Date | string) {
  const dateObj = new Date(date);
  return `${dateObj.getFullYear()}-${dateObj.getMonth()}-${dateObj.getDate()}`;
}
