export const messages = {
  confirmationHeader: {
    message: "Your appointment is booked!",
    description: "Header message for confirmation of booking",
  },
  confirmationSubHeader: {
    message: "You’ll receive an email confirmation at ",
    description: "message displayed below the main header",
  },
};
