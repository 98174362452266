import React from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Content } from "@jobber/components/Content";
import { useSetPageTitle } from "jobber/settings/users/hooks/useSetPageTitle";
import { messages } from "./messages";

export interface DisabledProps {
  name: string;
  phone?: string;
  email?: string;
}

export function Disabled({ name, phone, email }: DisabledProps) {
  useSetPageTitle(name || "");

  return (
    <Content spacing="small">
      <Text align="center" size="large">
        <Emphasis variation="bold">{messages.formNotAvailable.title}</Emphasis>
      </Text>
      <Text align="center">{contactMessageText(phone, email)}</Text>
    </Content>
  );
}

// I'm not using messages here because without `react-intl` we don't get support
// for params to messages, so template literals are easier to deal with.
function contactMessageText(phone?: string, email?: string) {
  if (phone == null && email == null) {
    return <>Please contact your service provider for assistance</>;
  } else if (phone == null && typeof email === "string") {
    return (
      <>
        Please contact us at <a href={`mailto:${email}`}>{email}</a> for
        assistance
      </>
    );
  } else if (typeof phone === "string" && email == null) {
    return (
      <>
        Please contact us at <a href={`tel:${phone}`}>{phone}</a> for assistance
      </>
    );
  } else {
    return (
      <>
        Please contact us at <a href={`tel:${phone}`}>{phone}</a> or{" "}
        <a href={email}>{email}</a> for assistance
      </>
    );
  }
}
