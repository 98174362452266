import { gql } from "@apollo/client";

export const ORGANIZATION_QUERY = gql`
  query OrganizationData($organizationId: EncodedId!) {
    organization(id: $organizationId) {
      id
      logoUrl
      name
      phone
      email
      countryCode
    }
  }
`;

export const ORGANIZATION_LEAD_ROUTES_QUERY = gql`
  query OrganizationLeadRoutes($organizationId: EncodedId!, $geoCode: String!) {
    organization(id: $organizationId) {
      id
      leadRoutes(geographicLocationCode: $geoCode) {
        nodes {
          ...OrganizationLeadRoute
        }
      }
    }
  }

  fragment OrganizationLeadRoute on LeadRoute {
    id
    name
    url
    routingCode
  }
`;
