import { defineMessages } from "react-intl";

export const messages = defineMessages({
  heading: {
    id: "onlineBooking.offeringsSelection.heading",
    defaultMessage: "Select your services",
    description: "Title of the OfferingsSelection page",
  },
  noSelectionsError: {
    id: "onlineBooking.offeringsSelection.noSelectionsError",
    defaultMessage: "Select at least one service",
    description:
      "Error message shown when SC does not select a service and tries to advance in the form",
  },
  defaultOfferingExpiredError: {
    id: "onlineBooking.offeringsSelection.defaultOfferingExpiredError",
    defaultMessage:
      "The service you selected has changed or is no longer available",
    description:
      "Error message shown when SC selects a service that is no longer available",
  },
});
