import React from "react";
import { InputPhoneNumber } from "@jobber/components/InputPhoneNumber";
import { useIntl } from "react-intl";
import { messages } from "./messages";

interface PhoneNumberWrapperInterface {
  invalidPhone: boolean;
  setInvalidPhone: (invalidPhone: boolean) => void;
  phoneNumber?: string;
  onFieldChanged: (newValue: string) => void;
}

export function PhoneNumberWrapper({
  invalidPhone,
  setInvalidPhone,
  phoneNumber,
  onFieldChanged,
}: PhoneNumberWrapperInterface) {
  const { formatMessage } = useIntl();

  return (
    <InputPhoneNumber
      placeholder={formatMessage(messages.phoneNumberFieldPlaceholder)}
      description={formatMessage(messages.phoneConsentDescription)}
      name="phone"
      value={phoneNumber || ""}
      onChange={onFieldChanged}
      required={true}
      autocomplete={true}
      invalid={invalidPhone}
      validations={{
        maxLength: 255,
      }}
      onBlur={() => {
        setInvalidPhone(false);
      }}
    />
  );
}
