export const messages = {
  heading: {
    message: "Review & confirm",
    description: "The review card header",
  },
  contactInfoHeading: {
    message: "Contact info",
    description: "The contact info section header",
  },
  serviceAddressHeading: {
    message: "Service address",
    description: "The service address section header",
  },
  jobDescriptionHeading: {
    message: "Additional details",
    description: "The job description section header",
  },
  appointmentTimeHeading: {
    message: "Appointment time",
    description: "The appointment time section header",
  },
  genericBookingError: {
    message: "An unexpected error has occurred. Please try again.",
    description:
      "Generic error message to display in banner when booking returns an error",
  },
};
