import { defineMessages } from "react-intl";

export const messages = defineMessages({
  uploadCountError: {
    id: "contact.ImageUpload.uploadCountError",
    defaultMessage: "File could not be uploaded. Exceeds 4 file limit.",
    description:
      "Message displayed to the user when they try and upload over the limit set.",
  },
  uploadTypeError: {
    id: "contact.ImageUpload.uploadTypeError",
    defaultMessage:
      "Upload type is not supported. Please try again with a png, jpg, jpeg, heic or pdf file.",
    description:
      "Message displayed to the user when they try and upload an invalid file type.",
  },
  uploadSizeError: {
    id: "contact.ImageUpload.uploadSizeError",
    defaultMessage:
      "File could not be uploaded. Please try again with a file under 10MB.",
    description:
      "Message displayed to the user when they try and upload a file that is too large.",
  },
  generalUploadError: {
    id: "contact.ImageUpload.generalUploadError",
    defaultMessage: "An error has occurred. Please try again.",
    description:
      "Message displayed to the user when something goes wrong with the upload.",
  },
  uploadInstructions: {
    id: "contact.ImageUpload.uploadInstructions",
    defaultMessage:
      "Do not upload files with payment information. Ensure you have all required rights, consent and permissions to share.",
    description: "warning displayed to the user when they upload",
  },
  uploadHeading: {
    id: "contact.ImageUpload.uploadHeading",
    defaultMessage: "Upload files",
    description: "Heading displayed to the user when they upload",
  },
  optional: {
    id: "contact.ImageUpload.optional",
    defaultMessage: "(optional)",
    description:
      "optional text displayed to the user when they upload if it is not required",
  },
  uploadButton: {
    id: "contact.ImageUpload.uploadButton",
    defaultMessage: "Upload File",
    description: "Button text displayed to the user when they upload",
  },
  uploadRequired: {
    id: "contact.ImageUpload.uploadButton",
    defaultMessage: "Uploading a file is required",
    description: "Enforce user to upload image",
  },
});
