export const messages = {
  termsAndConditionsDisclaimer: {
    message: (buttonLabel: string) =>
      `By clicking ‘${buttonLabel}’, you acknowledge that you have read, understand and agree to our`,
    description: "The disclaimer text for Terms and Conditions",
  },
  termsAndConditions: {
    message: "Terms and Conditions.",
    description: "Terms and Conditions text following the disclaimer",
  },
};
