import React from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Divider } from "@jobber/components/Divider";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { formatCurrency } from "utilities/formatCurrency";
import { getServicesSubtotal } from "components/ServiceList/utils/getServicesSubtotal";
import styles from "./styles.module.css";
import { messages } from "./messages";

export interface ServiceListItem {
  id: string;
  name: string;
  quantity: number;
  price: number;
}

interface ServiceListProps {
  services: ServiceListItem[];
  showHeader: boolean;
  currency: string;
}

export function ServiceList({
  services,
  showHeader,
  currency,
}: ServiceListProps) {
  const formattedServiceSubtotal = formatCurrency(
    getServicesSubtotal(services),
    currency,
  );

  return (
    <Content spacing="small">
      {showHeader && (
        <Heading level={5}>{messages.servicesHeading.message}</Heading>
      )}

      {services.map(service => {
        const { price, quantity, name } = service;

        return (
          <Service
            key={service.id}
            name={name}
            quantity={quantity}
            formattedPrice={formatCurrency(price, currency)}
            formattedSubtotal={formatCurrency(
              getServicesSubtotal([service]),
              currency,
            )}
          />
        );
      })}

      <Divider size="large" />

      <Service
        name={messages.estimatedSubtotalLabel.message}
        formattedSubtotal={formattedServiceSubtotal}
        quantity={1}
        formattedPrice=""
        isBold
      />
    </Content>
  );
}

interface ServiceProps {
  name: string;
  quantity: number;
  formattedPrice: string;
  formattedSubtotal: string;
  isBold?: boolean;
  key?: string;
}

export function Service({
  name,
  quantity,
  formattedPrice,
  formattedSubtotal,
  isBold = false,
}: ServiceProps) {
  function getText(text: string) {
    if (isBold) {
      return (
        <Text>
          <Emphasis variation="bold">{text}</Emphasis>
        </Text>
      );
    }

    return <Text>{text}</Text>;
  }

  return (
    <div className={styles.row}>
      <div className={styles.columnLeft}>
        {getText(name)}

        {quantity > 1 && (
          <Text size="small" variation="subdued">
            {`${quantity} × ${formattedPrice}`}
          </Text>
        )}
      </div>

      <div className={styles.columnRight}>{getText(formattedSubtotal)}</div>
    </div>
  );
}
