export const messages = {
  heading: {
    defaultMessage: "Book online",
    description: "Heading of the service areas page",
  },
  postalCodeLabelPostalCode: {
    defaultMessage: "postal code",
    description:
      "Label for the Postal Code field in countries that use Postal Code, e.g. CA",
  },
  postalCodeLabelZipCode: {
    defaultMessage: "ZIP code",
    description:
      "Label for the Postal Code field in countries that use ZIP Code, e.g. US",
  },
  postalCodeLabelDefault: {
    defaultMessage: "postal code",
    description:
      "Label for the Postal Code field in countries not otherwise specified",
  },
  errorBanner: {
    defaultMessage: "Something went wrong. Please try again.",
    description:
      "Error message for when the mutation for fetching the organization details fail",
  },
};
