import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { useBookingMachineContext } from "external/vendor/context";
import { errorInformation } from "external/vendor/messages";

export function ErrorModal() {
  const [current, send] = useBookingMachineContext();
  const statePath = current.toStrings().pop();
  const stateName = `${current.machine?.id}.${statePath}`;
  const error = current.meta[stateName] || errorInformation.defaultError;

  async function goToPageWithError() {
    send({
      type: "CONFIRM_ERROR_MODAL",
    });
  }
  return (
    <Modal
      open={true}
      dismissible={false}
      title={error.title}
      primaryAction={{
        label: error.primaryLabel,
        onClick: goToPageWithError,
      }}
    >
      <Content>
        <Text>{error.description}</Text>
      </Content>
    </Modal>
  );
}
