import React, { useCallback } from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { InputText } from "@jobber/components/InputText";
import { Content } from "@jobber/components/Content";
import { InputValidation } from "@jobber/components/InputValidation";
import { useBookingMachineContext } from "external/vendor/context";
import type { MachineContext } from "external/vendor/booking.machine";
import { messages } from "./messages";
import styles from "./styles.module.css";

interface ServiceAreaProps {
  countryCode?: string | undefined;
}

type AddressFields = keyof MachineContext["serviceAddress"];

export function ServiceArea({ countryCode = "US" }: ServiceAreaProps) {
  const geocodeCountryStyle = (() => {
    switch (countryCode) {
      case "CA":
        return messages.postalCodeLabelPostalCode.defaultMessage;
      case "US":
        return messages.postalCodeLabelZipCode.defaultMessage;
      default:
        return messages.postalCodeLabelDefault.defaultMessage;
    }
  })();

  const [current, send] = useBookingMachineContext();
  const onFieldChanged = useCallback(
    (fieldName: AddressFields) => (newValue: string) =>
      send({
        type: "ADDRESS_CHANGED",
        address: { [fieldName]: newValue },
      }),
    [send],
  );
  const isOutsideServiceArea = current.matches("ServiceArea.OutOfServiceArea");

  return (
    <Content>
      <div className={styles.centered}>
        <Heading level={3}>{messages.heading.defaultMessage}</Heading>
      </div>
      <div className={styles.centered}>
        <Text>{`Enter your ${geocodeCountryStyle} to get started`}</Text>
      </div>
      <div className={styles.items}>
        <InputText
          prefix={{ icon: "address" }}
          placeholder={
            geocodeCountryStyle.charAt(0).toUpperCase() +
            geocodeCountryStyle.slice(1)
          }
          value={current.context.serviceAddress.postalCode}
          onChange={onFieldChanged("postalCode")}
          validations={{
            required: {
              value: true,
              message: `Enter a valid ${geocodeCountryStyle} to continue.`,
            },
          }}
        />
        {isOutsideServiceArea && (
          <ValidationMessage
            phone={current.context.vendor.phone}
            email={current.context.vendor.email}
          />
        )}
      </div>
    </Content>
  );
}

function ValidationMessage({
  phone,
  email,
}: {
  phone?: string;
  email?: string;
}) {
  let message = "Unavailable for this area. Please contact us for assistance.";

  if (phone && email) {
    message = `Unavailable for this area. Please contact us at ${phone} or ${email} for assistance.`;
  } else if (phone) {
    message = `Unavailable for this area. Please contact us at ${phone} for assistance.`;
  } else if (email) {
    message = `Unavailable for this area. Please contact us at ${email} for assistance.`;
  }

  return <InputValidation message={message} />;
}
