import React, { useEffect } from "react";
import { BrowserRouter as RoutingProvider } from "react-router-dom";
import { APIProvider } from "external/utils/APIProvider";
import { FormRoute } from "external/vendor/FormRoute";
import type { AddressConfiguration } from "components/InputAddress/types";
import { AddressConfigurationProvider } from "components/InputAddress";
import { useSetPageTitle } from "jobber/settings/users/hooks/useSetPageTitle";
import { initialize as initializeAnalytics } from "external/Analytics/Analytics";
import { DisplayHint } from "./types";
import styles from "./styles.app.module.css";

export interface SelectedService {
  fingerprint: string;
  matched: boolean;
}
export interface AppProps {
  routerBasename: string;
  graphqlUri: string;
  amplitudeApiKey: string;
  accountId: string;
  uuid: string;
  addressConfig: AddressConfiguration;
  name?: string;
  displayHint?: DisplayHint;
  selectedService?: SelectedService;
  trackingSuborigin?: string;
}

export function App({
  routerBasename,
  graphqlUri,
  uuid,
  selectedService,
  addressConfig,
  name,
  amplitudeApiKey,
  accountId,
  trackingSuborigin,
  displayHint = DisplayHint.OFFERINGS,
}: AppProps): JSX.Element {
  useSetPageTitle(name || "");
  initializeAnalytics({ amplitudeApiKey, accountId });
  return (
    // This is applying base styling not restricted to any one component
    // the styles css does not have a class baseStyles but this applies styling to html and body elements
    <div className={styles.baseStyles}>
      <AddressConfigurationProvider value={addressConfig}>
        <APIProvider uri={graphqlUri}>
          <ScrollToTop />
          <RoutingProvider basename={routerBasename}>
            <FormRoute
              uuid={uuid}
              displayHint={displayHint}
              selectedService={selectedService}
              trackingSuborigin={trackingSuborigin}
            />
          </RoutingProvider>
        </APIProvider>
      </AddressConfigurationProvider>
    </div>
  );
}

export function ScrollToTop() {
  // The value of `scrollRestoration` will persist browser refreshes because it is a property on the history.
  // More on history: https://developer.mozilla.org/en-US/docs/Web/API/Window/history
  useEffect(() => {
    if (window.history.scrollRestoration === "auto") {
      window.history.scrollRestoration = "manual";
    }
    window.addEventListener("popstate", scrollToTop);
    return function cleanup() {
      window.removeEventListener("popstate", scrollToTop);
    };
  }, []);

  return null;

  function scrollToTop() {
    window.scrollTo(0, 0);
  }
}
