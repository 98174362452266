import { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import type {
  DirectUploadQuery,
  DirectUploadQueryVariables,
} from "~/utilities/API/graphqlExternal";
import { DIRECT_UPLOAD_QUERY } from "~/components/ImageUpload/upload.external.graphql";

export const useDirectUpload = () => {
  const [directUpload] = useLazyQuery<
    DirectUploadQuery,
    DirectUploadQueryVariables
  >(DIRECT_UPLOAD_QUERY);

  const fetchDirectUploadParams = useCallback(
    async (file: File, identifier?: string) => {
      const identifierString = identifier ? `${identifier}_` : "";
      const { data, error } = await directUpload({
        variables: {
          filename: `${identifierString}${file.name}`,
          contentType: file.type,
        },
      });
      if (error) {
        return error;
      }
      if (data) {
        const { url, fields } = data.directUpload;

        return {
          url,
          key: data?.directUpload.id,
          fields: {
            key: fields.key,
            acl: fields.acl,
            policy: fields.policy,
            /* eslint-disable @typescript-eslint/naming-convention */
            "x-amz-credential": fields.xAmzCredential,
            "x-amz-algorithm": fields.xAmzAlgorithm,
            "x-amz-date": fields.xAmzDate,
            "x-amz-signature": fields.xAmzSignature,
            /* eslint-enable @typescript-eslint/naming-convention */
            "Content-Type": file.type,
          },
        };
      }
      return {
        url: "",
        key: "GENERAL_ERROR",
      };
    },
    [directUpload],
  );
  return {
    fetchDirectUploadParams,
  };
};
