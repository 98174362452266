import type { AvailabilitySlot } from "~/utilities/API/graphqlExternal";
import type { useBookingMachineContext } from "./context";

export type MachineState = ReturnType<typeof useBookingMachineContext>[0];
export type Dispatcher = ReturnType<typeof useBookingMachineContext>[1];

export enum DisplayHint {
  SERVICE_AREA = "SERVICE_AREA",
  OFFERINGS = "OFFERINGS",
}

export interface GeocodeResult {
  readonly latitude: number | undefined;
  readonly longitude: number | undefined;
}

export interface Address {
  street1: string | undefined;
  street2: string | undefined;
  city: string | undefined;
  province: string | undefined;
  postalCode: string | undefined;
  country: string | undefined;
  longitude: number | undefined;
  latitude: number | undefined;
}

export interface Offering {
  id: string;
  basePrice: number;
  description?: string;
  durationMinutes: number;
  name: string;
  fingerprint: string;
  quantityRange?: { minQuantity: number; maxQuantity: number };
}

export interface SelectedOffering extends Offering {
  quantity: number;
}

export interface SocialLink {
  socialPlatform:
    | "angieslist"
    | "facebook"
    | "google"
    | "instagram"
    | "twitter"
    | "yelp";
  url: string;
}

export type Appointment = Pick<
  AvailabilitySlot,
  "id" | "duration" | "startTime" | "endTime"
>;

export interface Vendor {
  id: string;
  name: string;
  arrivalWindowCenteredOnStartTime: boolean;
  acceptSelfServeBookings: boolean;
  countryCode?: string;
  currency: string;
  logoUrl?: string;
  showCompanyName?: boolean;
  email?: string;
  confirmationMessage?: string;
  termsAndConditionsUrl?: string;
  arrivalWindowDuration?: number;
  phone?: string;
  websiteUrl?: string;
  socialLinks: SocialLink[];
  offerings: Offering[];
  address?: {
    city?: string;
    country?: string;
    postalCode?: string;
    province?: string;
    street1?: string;
    street2?: string;
  };
  locationRequired: boolean;
  serviceAreasEnabled: boolean;
}
