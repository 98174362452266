export function fetchCookie(cookieName: string): string | undefined {
  const cookieValue = document.cookie
    .split("; ")
    .find(row => row.startsWith(`${cookieName}=`))
    ?.split("=")?.[1];

  if (cookieValue) {
    return decodeURIComponent(cookieValue);
  }
}
