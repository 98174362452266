import React from "react";
import { APIProvider } from "external/utils/APIProvider";
import { RoutingForm } from "./components/RoutingForm/RoutingForm";

interface AppProps {
  id: string;
  graphqlUri: string;
}

export function App({ id, graphqlUri }: AppProps): JSX.Element {
  return (
    <APIProvider uri={graphqlUri}>
      <RoutingForm id={id} />
    </APIProvider>
  );
}
