import { useCallback } from "react";

type GoogleApi = typeof google;

export function useLocationGeocode(google: GoogleApi) {
  const geocode = useCallback(
    ({ address, componentRestrictions }: google.maps.GeocoderRequest) => {
      const geocoder = new google.maps.Geocoder();

      return geocoder.geocode({ address, componentRestrictions });
    },
    [google],
  );

  return {
    geocode,
  };
}
