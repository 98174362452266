import React from "react";
// DO NOT COPY: Allowing an exception here for specific styling Typography.
// Header needs to be less bold than Heading provides
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { useSelector } from "@xstate/react";
import { BookingMachineContext } from "external/vendor/context";
import styles from "./styles.module.css";
import { FormHeaderSkeleton } from "./FormHeader.skeleton";

export interface FormHeaderProps {
  backAction?(): JSX.Element;
  logoUrl?: string;
  name?: string;
  showCompanyName?: boolean;
}

const showForMobile = [styles.headerElement, styles.showForMobile].join(" ");

export function FormHeader({
  backAction,
  logoUrl,
  name,
  showCompanyName,
}: FormHeaderProps) {
  const hasLogoAndName = logoUrl && showCompanyName;

  const bookingService = React.useContext(BookingMachineContext);
  const loadingVendor = useSelector(bookingService.bookingMachine, actor => {
    return actor.matches("RequestVendor");
  });
  return (
    <div className={styles.headerContainer}>
      <div className={showForMobile}>{backAction?.()}</div>
      <div className={styles.headerElement}>
        {loadingVendor ? (
          <FormHeaderSkeleton />
        ) : (
          <>
            {logoUrl && (
              <img src={logoUrl} className={styles.logo} alt={name} />
            )}
            {(!logoUrl || hasLogoAndName) && (
              <div className={styles.companyName}>
                <Typography
                  element="h3"
                  size="larger"
                  fontWeight="bold"
                  textColor="heading"
                >
                  {name}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
      <div className={showForMobile}></div>
    </div>
  );
}
