import { gql } from "@apollo/client";

export const BOOKING_CREATE_MUTATION = gql`
  mutation BookingCreate($vendorId: EncodedId!, $input: BookingCreateInput!) {
    bookingCreate(vendorId: $vendorId, input: $input) {
      booking {
        id
      }
      userErrors {
        message
        path
      }
    }
  }
`;
