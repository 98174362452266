import type { Address } from "external/vendor/types";

export const address = {
  full: (components: Address) => {
    return [
      components.street1,
      components.street2,
      components.city,
      components.province,
      components.postalCode,
      components.country,
    ]
      .filter(component => !!component) // null/undefined/empty
      .join(", ");
  },
};
