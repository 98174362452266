export const messages = {
  servicesHeading: {
    message: "Services",
    description: "The services section header",
  },
  estimatedSubtotalLabel: {
    message: "Estimated subtotal (excl. tax)",
    description: "The label for the estimated subtotal section",
  },
};
