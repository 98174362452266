import React from "react";
import type { Offering, SelectedOffering } from "external/vendor/types";
import styles from "./OfferingsSelectionList.module.css";
import { SelectionBox } from "../SelectionBox";

export interface OfferingsSelectionProps {
  currency: string;
  offerings: Offering[];
  selectedOfferings: SelectedOffering[];
  handleOfferingsClick(
    offering: Offering,
    type: "SELECTED_OFFERING" | "DESELECTED_OFFERING",
  ): void;
}

export function OfferingsSelectionList({
  currency,
  offerings,
  selectedOfferings,
  handleOfferingsClick,
}: OfferingsSelectionProps): JSX.Element {
  const combinedOfferings = offerings.map(offering => ({
    ...selectedOfferings.find(
      selectedOffering =>
        selectedOffering.id === offering.id && selectedOffering,
    ),
    ...offering,
  }));

  return (
    <ul className={styles.offeringsList}>
      {combinedOfferings.map(offering => {
        return (
          <SelectionBox
            key={offering.id}
            currency={currency}
            offering={offering}
            quantity={offering.quantity || 0}
            onOfferingsClick={handleOfferingsClick}
          />
        );
      })}
    </ul>
  );
}
