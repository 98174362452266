import { Text } from "@jobber/components/Text";
import React from "react";
import { format } from "external/vendor/utils/format";
import { SecondaryContent } from "./components/SecondaryContent";
import { Socials } from "./components/Socials";
import styles from "./styles.module.css";

export type socialNames =
  | "facebook"
  | "google"
  | "twitter"
  | "instagram"
  | "angieslist"
  | "yelp";
export interface SocialLink {
  url: string;
  socialPlatform: socialNames;
}

export interface Address {
  street1?: string;
  street2?: string;
  city?: string;
  province?: string;
  country?: string;
  postalCode?: string;
}
export interface FormFooterProps {
  name: string;
  email?: string;
  phone?: string;
  websiteUrl?: string;
  address?: Address;
  socialLinks?: SocialLink[];
}

export function FormFooter({
  email,
  phone,
  address,
  websiteUrl,
  name,
  socialLinks,
}: FormFooterProps) {
  const formattedWebsiteUrl = websiteUrl ? format.url(websiteUrl) : undefined;
  const formattedAddress = address ? formatAddress(address) : undefined;
  const socialsPresent = socialLinks ? socialLinks.length > 0 : undefined;

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <Text size="large">{name}</Text>
        </div>

        {socialLinks && socialsPresent && <Socials socialLinks={socialLinks} />}
        {formattedAddress && <Text>{formattedAddress}</Text>}

        <div className={styles.contactContainer}>
          {phone && <Text>{phone}</Text>}
          {email && <Text>{email}</Text>}
        </div>
        {websiteUrl && (
          <div className={styles.websiteWrapper}>
            <Text>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={formattedWebsiteUrl}
              >
                Visit website
              </a>
            </Text>
          </div>
        )}
      </div>
      <SecondaryContent />
    </div>
  );
}

function formatAddress(address: Address) {
  let finalAddress = "";
  if (address.street1) finalAddress += `${address.street1}`;
  if (address.street2) finalAddress += `, ${address.street2}`;
  if (address.city) finalAddress += `, ${address.city}`;
  if (address.province) finalAddress += `, ${address.province}`;
  if (address.postalCode) finalAddress += ` ${address.postalCode}`;
  return finalAddress;
}
