import React, { type ProviderProps } from "react";
import { useQuery } from "@apollo/client";
import type { VendorQuery } from "~/utilities/API/graphqlExternal";
import { VENDOR_QUERY } from "external/vendor/graphql";

interface ArrivalWindowContextType {
  arrivalWindowDuration: number;
  arrivalWindowCentered: boolean;
}

export const ArrivalWindowContext =
  React.createContext<ArrivalWindowContextType>({
    arrivalWindowDuration: 0,
    arrivalWindowCentered: false,
  });

interface ArrivalWindowContextProviderProps {
  uuid: string;
}

export function ArrivalWindowContextProvider(
  props: ProviderProps<ArrivalWindowContextProviderProps>,
) {
  const { value, children } = props;
  const { data } = useQuery<VendorQuery>(VENDOR_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: { id: value.uuid },
  });

  return (
    <ArrivalWindowContext.Provider
      value={{
        arrivalWindowDuration: data?.vendor?.arrivalWindowDuration || 0,
        arrivalWindowCentered:
          data?.vendor?.arrivalWindowCenteredOnStartTime || false,
      }}
    >
      {children}
    </ArrivalWindowContext.Provider>
  );
}
