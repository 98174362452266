/* eslint-disable max-statements */
import React, { useState } from "react";
import {
  type FileUpload,
  InputFile,
  updateFiles,
} from "@jobber/components/InputFile";
import { InputValidation } from "@jobber/components/InputValidation";
import { FormatFile } from "@jobber/components/FormatFile";
import classNames from "classnames";
import { ApolloError } from "@apollo/client";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
import { useDirectUpload } from "~/components/ImageUpload/hooks/useDirectUpload";
import { useLegacyForm } from "~/components/ImageUpload/hooks/useLegacyForm";
import styles from "./styles.module.css";
import { messages } from "./messages";

const MAX_NUM_IMAGES = 4;
const MAX_IMAGE_SIZE = 10000000;

// These props are to be utilized when the state needs to be synced outside of the component and are not required.
// An example of this is the online booking form.
interface ImageUploadProps {
  onChange?: (files: FileUpload[]) => void;
  files?: FileUpload[];
  /**
   * Specifies whether an input should be be attached to the DOM.
   * This is used for external requests that are not using react.
   */
  attachToDOM?: boolean;
  heading?: string;
  subheading?: string;
  required?: boolean;
}

export function ImageUpload(props: ImageUploadProps) {
  const { formatMessage } = useIntl();
  const [files, setFiles] = useState<FileUpload[]>(props.files || []);
  const [uploadError, setUploadError] = useState("");
  const { fetchDirectUploadParams } = useDirectUpload();
  const { smallOnly, extraSmallOnly } = useBreakpoints();
  const smallOrExtraSmall = extraSmallOnly || smallOnly;
  const hasFiles = files?.length > 0;
  const handleGetUploadParams = async (file: File) => {
    const params = await fetchDirectUploadParams(file, files.length.toString());
    if (params instanceof ApolloError) {
      if (params.message.includes("Upload type is not supported")) {
        setUploadError(formatMessage(messages.uploadTypeError));
        throw new Error("TYPE_ERROR");
      }
      setUploadError(formatMessage(messages.generalUploadError));
      throw new Error("GENERAL_ERROR");
    }
    if (files.length >= MAX_NUM_IMAGES) {
      setUploadError(formatMessage(messages.uploadCountError));
      throw new Error("MAX_COUNT_ERROR");
    }
    setUploadError("");
    return params;
  };

  const handleUpload = (file: FileUpload) => {
    setFiles(oldFiles => {
      return updateFiles(file, oldFiles);
    });
  };

  const handleOnComplete = (file: FileUpload) => {
    setFiles([...files, file]);
    props.onChange && props.onChange([...files, file]);
  };

  const handleOnDeleteUpload = (file: FileUpload) => {
    const reducedFiles = files.filter(f => f.key !== file.key);
    setFiles(reducedFiles);
    props.onChange && props.onChange(reducedFiles);
  };

  const fileValidator = (file: File) => {
    if (file.size > MAX_IMAGE_SIZE) {
      return {
        code: "MAX_SIZE_ERROR",
        message: formatMessage(messages.uploadSizeError),
      };
    }
    return null;
  };

  const handleError = (e: Error) => {
    if (
      e.message.includes("Failed to get upload params") &&
      files.length >= 4
    ) {
      setUploadError(formatMessage(messages.uploadCountError));
    } else {
      setUploadError(formatMessage(messages.generalUploadError));
    }
  };

  useLegacyForm({
    files,
    attachToDOM: props.attachToDOM,
    required: props.required,
    setUploadError,
  });

  return (
    <>
      <div className={classNames(styles.headingWrapper)}>
        <Heading level={5}>
          {props.heading || formatMessage(messages.uploadHeading)}
        </Heading>
        <div className={classNames(styles.required)}>
          <Text size="base" variation="subdued">
            {!props.required && formatMessage(messages.optional)}
          </Text>
        </div>
      </div>
      <div>
        <Text variation={"subdued"} size={"small"}>
          {props.subheading}
        </Text>
      </div>
      <InputFile
        getUploadParams={handleGetUploadParams}
        allowedTypes={["PNG", "JPEG", "HEIC", "PDF"]}
        onUploadComplete={handleOnComplete}
        onUploadProgress={handleUpload}
        onUploadError={handleError}
        variation={smallOrExtraSmall ? "button" : "dropzone"}
        validator={fileValidator}
        buttonLabel={formatMessage(messages.uploadButton)}
      />
      {hasFiles && smallOrExtraSmall ? (
        <div className={classNames(styles.row)}>
          {files.map(file => (
            <div key={file.key} className={styles.rowItems}>
              <FormatFile
                file={file}
                key={file.key}
                onDelete={() => handleOnDeleteUpload(file)}
                display="compact"
                displaySize="base"
              />
            </div>
          ))}
        </div>
      ) : (
        <div className={classNames(styles.list, styles.large)}>
          {files.map(file => (
            <div key={file.key} className={styles.fileParent}>
              <FormatFile
                file={file}
                key={file.key}
                onDelete={() => handleOnDeleteUpload(file)}
              />
            </div>
          ))}
        </div>
      )}
      {uploadError && <InputValidation message={uploadError} />}
      <div className={classNames(styles.instructions)}>
        <Text variation={"subdued"} size={"small"}>
          {formatMessage(messages.uploadInstructions)}
        </Text>
      </div>
    </>
  );
}
