import { gql } from "@apollo/client";

export const DIRECT_UPLOAD_QUERY = gql`
  query DirectUpload($filename: String!, $contentType: String!) {
    directUpload(filename: $filename, contentType: $contentType) {
      id
      fields {
        acl
        key
        policy
        xAmzAlgorithm
        xAmzCredential
        xAmzDate
        xAmzSignature
      }
      filename
      url
    }
  }
`;
