import type { ServiceListItem } from "components/ServiceList";
import { roundToDecimals } from "utilities/math";

export function getServicesSubtotal(services: ServiceListItem[]): number {
  const totalCost = services.reduce((accumulator, service) => {
    const { quantity, price } = service;
    const serviceSubtotal = quantity * price;

    return accumulator + serviceSubtotal;
  }, 0);

  return roundToDecimals(totalCost, 2);
}
