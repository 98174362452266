import { Glimmer } from "@jobber/components/Glimmer";
import React from "react";
import styles from "./styles.module.css";

const BOOKING_PLACEHOLDERS = 4;

export function SelfServeBookingDaySlotsPickerSkeleton() {
  return (
    <div className={styles.skeletonWrapper}>
      <div className={styles.skeletonDateWrapper}>
        <Glimmer />
      </div>
      {Array.from(Array(BOOKING_PLACEHOLDERS).keys()).map(key => (
        <div key={key} className={styles.cardBorder}>
          <div className={styles.skeletonTimeSlot}>
            <Glimmer />
          </div>
        </div>
      ))}
    </div>
  );
}
