import React, { useContext, useRef } from "react";
import { Form } from "@jobber/components/Form";
import { Card } from "@jobber/components/Card";
import { useActor } from "@xstate/react";
import {
  type GoogleAPI,
  GoogleApiWrapper,
} from "@jobber/google-maps-react-community";
import { IntlProvider } from "@translations/IntlProvider";
import { FormHeader } from "external/vendor/components/FormHeader";
import { FormFooter } from "external/vendor/components/FormFooter";
import { ActionBar, BackAction } from "external/vendor/components";
import { AddressConfigurationContext } from "components/InputAddress/AddressConfigurationContext";
import type { SelectedService } from "external/vendor/App";
import styles from "./styles.module.css";
import { ArrivalWindowContextProvider, BookingMachineContext } from "./context";
import { useBookingStateMachine } from "./useBookingStateMachine";
import { DisplayHint } from "./types";
import {
  AppointmentSelection,
  Confirmation,
  Contact,
  Disabled,
  OfferingsSelection,
  OfferingsSelectionSkeleton,
  Review,
  ServiceArea,
  ServiceAreaSkeleton,
} from "./views";

interface FormRouteProps {
  uuid: string;
  selectedService?: SelectedService;
  displayHint: DisplayHint;
  trackingSuborigin?: string;
}
function Loading({ displayHint }: { displayHint: DisplayHint }): JSX.Element {
  switch (displayHint) {
    case DisplayHint.SERVICE_AREA: {
      return <ServiceAreaSkeleton />;
    }
    case DisplayHint.OFFERINGS: {
      return <OfferingsSelectionSkeleton />;
    }
  }
}

export function FormRoute(props: FormRouteProps) {
  const { googleApiKey } = useContext(AddressConfigurationContext);
  const FormRouteWrapper = GoogleApiWrapper({
    apiKey: googleApiKey ?? "",
    LoadingContainer: () => <></>,
  })(FormRouteInternal);

  return <FormRouteWrapper {...props} />;
}

type FormRouteInternalProps = FormRouteProps & {
  google: GoogleAPI;
};

function FormRouteInternal({
  uuid,
  selectedService,
  google,
  displayHint,
  trackingSuborigin,
}: FormRouteInternalProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const bookingMachine = useBookingStateMachine(
    uuid,
    google,
    trackingSuborigin,
  );
  const [current, send] = useActor(bookingMachine);

  return (
    <IntlProvider>
      <Form
        onStateChange={formState => {
          send({ type: "FORM_CHANGED", formState });
        }}
      >
        <BookingMachineContext.Provider value={{ bookingMachine }}>
          <ArrivalWindowContextProvider value={{ uuid }}>
            <div className={styles.container}>
              <FormHeader
                backAction={() => <BackAction iconOnly />}
                name={current.context.vendor.name}
                logoUrl={current.context.vendor.logoUrl}
                showCompanyName={current.context.vendor.showCompanyName}
              />
              <div className={styles.content} ref={contentRef} tabIndex={-1}>
                <div className={styles.section}>
                  <Card>
                    {(current.matches("RequestVendor") ||
                      current.matches("Offering.reloadOfferings")) && (
                      <Loading displayHint={displayHint} />
                    )}
                    {current.matches("Disabled") && (
                      <Disabled {...current.context.vendor} />
                    )}
                    {current.matches("ServiceArea") && (
                      <ServiceArea
                        countryCode={current.context.vendor.countryCode}
                      />
                    )}
                    {current.matches("Offering") && (
                      <OfferingsSelection defaultService={selectedService} />
                    )}
                    {current.matches("ContactInfo") && <Contact />}
                    {current.matches("Appointment") && (
                      <AppointmentSelection uuid={uuid} />
                    )}
                    {current.matches("Review") && <Review />}
                    {current.matches("Confirm") && <Confirmation />}
                  </Card>
                  <ActionBar onNavigate={resetFocus} />
                </div>
              </div>

              <FormFooter {...current.context.vendor} />
            </div>
          </ArrivalWindowContextProvider>
        </BookingMachineContext.Provider>
      </Form>
    </IntlProvider>
  );

  function resetFocus() {
    contentRef.current?.focus({ preventScroll: true });
  }
}
