import { gql } from "@apollo/client";

export const SERVICE_AREA_QUERY = gql`
  query ServiceArea(
    $latitude: Float!
    $longitude: Float!
    $vendorId: EncodedId!
  ) {
    vendorServiceArea(
      latitude: $latitude
      longitude: $longitude
      vendorId: $vendorId
    ) {
      isInsideServiceArea
    }
  }
`;
