import React, { type MouseEvent } from "react";
import { Text } from "@jobber/components/Text";
import type { Offering } from "external/vendor/types";
import { messages } from "./messages";
import styles from "./styles.module.css";

const TRUNCATED_MAX_DESCRIPTION_LENGTH = 140;

interface SelectionBoxDescriptionProps extends Pick<Offering, "description"> {
  readonly isExpanded: boolean;
  readonly onExpand: (isExpanded: boolean) => void;
}

export function SelectionBoxDescription({
  description,
  isExpanded,
  onExpand,
}: SelectionBoxDescriptionProps) {
  const truncatedDescription =
    description?.slice(0, TRUNCATED_MAX_DESCRIPTION_LENGTH) + "...";
  const isTruncated =
    description && description.length > TRUNCATED_MAX_DESCRIPTION_LENGTH;

  if (!isExpanded && isTruncated) {
    return (
      <Text>
        {/* Read the full description and hide the truncated text from screen readers */}
        <span aria-label={description} data-testid="truncated-text">
          <span aria-hidden={true}>
            {truncatedDescription + " "}
            <button onClick={handleClick}>
              {messages.expandDescription.message}
            </button>
          </span>
        </span>
      </Text>
    );
  }

  return (
    <div className={styles.showWhiteSpace}>
      <Text>{description}</Text>
    </div>
  );

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    onExpand(true);
  }
}
