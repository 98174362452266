import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import classNames from "classnames";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { AnimatedSwitcher } from "@jobber/components/AnimatedSwitcher";
import { Heading } from "@jobber/components/Heading";
import { useLiveAnnounce } from "@jobber/hooks/useLiveAnnounce";
import { QuantityPicker } from "external/vendor/views/OfferingsSelections/components/QuantityPicker";
import type { Offering } from "external/vendor/types";
import { minutesToDuration } from "utilities/time/minutesToDuration";
import { formatCurrency } from "utilities/formatCurrency";
import styles from "./styles.module.css";
import { messages } from "./messages";
import { SelectionBoxDescription } from "./SelectionBoxDescription";

export interface SelectionBoxProps {
  currency: string;
  offering: Offering;
  quantity: number;
  onOfferingsClick(
    offering: Offering,
    type: "SELECTED_OFFERING" | "DESELECTED_OFFERING",
  ): void;
}

export function SelectionBox({
  currency,
  offering,
  quantity,
  onOfferingsClick,
}: SelectionBoxProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const formattedDuration = minutesToDuration(offering.durationMinutes);
  const offeringWrapperStyles = classNames(styles.offering, {
    [styles.selected]: quantity > 0,
  });

  const { liveAnnounce } = useLiveAnnounce();
  const handleQuantityChange = (value: number) => {
    if (value > quantity) {
      onOfferingsClick(offering, "SELECTED_OFFERING");
      liveAnnounce(
        `${offering.name}, ${messages.offeringAddedAnnouncement.message}`,
      );
    } else {
      handleRemove();
    }
  };

  const handleRemove = () => {
    onOfferingsClick(offering, "DESELECTED_OFFERING");
    liveAnnounce(
      `${offering.name}, ${messages.offeringRemovedAnnouncement.message}`,
    );
  };

  return (
    // It's much easier for the user to allow screen readers to traverse through
    // text and buttons one by one instead of setting this to have a role of
    // `option` and be focusable.
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <li
      key={offering.id}
      onClick={handleOfferingClick}
      className={offeringWrapperStyles}
    >
      <div className={styles.infoContainer}>
        <div className={styles.nameDescriptionContainer}>
          <Content spacing={"small"}>
            <Heading level={5}>{offering.name}</Heading>
            <SelectionBoxDescription
              description={offering.description}
              isExpanded={isExpanded}
              onExpand={setIsExpanded}
            />
          </Content>
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.priceDurationContainer}>
            <Text size="base">
              <Emphasis variation="bold">
                {formatCurrency(offering.basePrice, currency)}
              </Emphasis>
            </Text>
            <Text variation="subdued">
              <span aria-hidden>&#183;</span>
            </Text>
            <Text variation="subdued">{formattedDuration}</Text>
          </div>

          <AnimatedSwitcher
            switched={quantity > 0}
            initialChild={
              <Button
                type="secondary"
                icon="plus2"
                label={messages.addButtonLabel.message}
                ariaLabel={`${messages.addButtonLabel.message} ${offering.name}`}
              />
            }
            switchTo={
              !offering.quantityRange ? (
                <Button
                  type="secondary"
                  icon="checkmark"
                  label={messages.addedButtonLabel.message}
                  ariaLabel={`${messages.removeButtonLabel.message} ${offering.name}`}
                />
              ) : (
                <QuantityPicker
                  min={offering.quantityRange.minQuantity}
                  max={offering.quantityRange.maxQuantity}
                  value={quantity}
                  onChange={handleQuantityChange}
                  onRemove={handleRemove}
                />
              )
            }
          />
        </div>
      </div>
    </li>
  );

  function handleOfferingClick() {
    if (quantity === 0) {
      onOfferingsClick(offering, "SELECTED_OFFERING");
      liveAnnounce(
        `${offering.name}, ${messages.offeringAddedAnnouncement.message}`,
      );
    } else if (!offering.quantityRange?.minQuantity && quantity === 1) {
      onOfferingsClick(offering, "DESELECTED_OFFERING");
      liveAnnounce(
        `${offering.name}, ${messages.offeringRemovedAnnouncement.message}`,
      );
    }
  }
}
