import { useMutation } from "@apollo/client";
import type { BookingCreateMutation } from "~/utilities/API/graphqlExternal";
import { BOOKING_CREATE_MUTATION } from "./Booking.graphql";

export function useCreateBooking() {
  const [createBooking, { loading, error }] =
    useMutation<BookingCreateMutation>(BOOKING_CREATE_MUTATION);

  return {
    createBooking,
    loading,
    error,
  };
}
