export const messages = {
  nextButton: {
    title: "Continue",
    description: "Label for Next button",
  },
  backButton: {
    title: "Back",
    description: "Label for Back button",
  },
  confirmAppointmentTimeButtonWithoutWindow: {
    title: "Confirm Appointment Time",
    description: "Label for Confirm Appointment Time button",
  },
  confirmAppointmentTimeButtonWithWindow: {
    title: "Confirm Arrival Time",
    description: "Label for Confirm Appointment Time button",
  },
  confirmBookingButton: {
    title: "Confirm Booking",
    description: "Label for Confirm Booking button",
  },
};

export const errorInformation = {
  slotUnavailableError: {
    title: "Appointment time unavailable",
    primaryLabel: "Select New Time",
    description: "The time slot you have selected is no longer available.",
  },
  serviceChangedError: {
    title: "Services changed",
    primaryLabel: "Back to Services",
    description:
      "Duration, price, or availability of some services have changed. Please return to review your selections.",
  },
  phoneNumberError: {
    title: "Invalid phone number",
    primaryLabel: "Back to Contact Information",
    description:
      "The phone number entered is not a valid number. Please update this and try again.",
  },
  vendorNotAcceptingBookingsError: {
    title: "Booking form is not available",
    primaryLabel: "Refresh",
    description:
      "We are not accepting bookings at this time, please contact us for assistance.",
  },
  defaultError: {
    title: "Something went wrong",
    primaryLabel: "Restart Booking",
    description: "Your booking could not be submitted, please try again.",
  },
};
