export function url(raw: string) {
  const formattedUrl = raw.toLowerCase();
  if (
    formattedUrl.startsWith("http://") ||
    formattedUrl.startsWith("https://")
  ) {
    return formattedUrl;
  }
  return `//${formattedUrl}`;
}
