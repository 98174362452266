import { Glimmer } from "@jobber/components/Glimmer";
import React from "react";
import styles from "./styles.module.css";

export function FormHeaderSkeleton() {
  return (
    <div className={styles.contentSkeleton}>
      <Glimmer size="auto" />
    </div>
  );
}
