import React, { useContext } from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Banner } from "@jobber/components/Banner";
import { FormatDate } from "@jobber/components/FormatDate";
import type { Appointment } from "external/vendor/types";
import type { MachineContext } from "external/vendor/booking.machine";
import { ErrorModal } from "external/vendor/views/Review/ErrorModal";
import { ServiceList } from "components/ServiceList";
import type { ServiceListItem } from "components/ServiceList";
import { format } from "external/vendor/utils/format";
import {
  ArrivalWindowContext,
  useBookingMachineContext,
} from "external/vendor/context";
import { TermsAndConditions } from "external/vendor/components/TermsAndConditions";
import { minutesToDuration } from "utilities/time/minutesToDuration";
import styles from "./styles.module.css";
import { messages } from "./messages";

export function Review(): JSX.Element {
  const [current] = useBookingMachineContext();

  const {
    contactInfo,
    serviceAddress,
    jobDescription,
    appointment,
    selectedOfferings,
    vendor,
  } = current.context;

  const services: ServiceListItem[] = selectedOfferings.map(offering => {
    return { ...offering, price: offering.basePrice };
  });

  return (
    <>
      {current.matches("Review.error") && (
        <Banner type="error" dismissible={false}>
          {messages.genericBookingError.message}
        </Banner>
      )}
      <Content spacing="large">
        <Heading level={3}>{messages.heading.message}</Heading>
        <ContactInfo contactInfo={contactInfo} />
        <ServiceAddress serviceAddress={serviceAddress} />
        <JobDescription jobDescription={jobDescription} />
        <AppointmentTime appointment={appointment} />
        <ServiceList
          currency={vendor.currency}
          services={services}
          showHeader={true}
        />
        <ConfirmationMessage confirmationMessage={vendor.confirmationMessage} />
        <TermsAndConditions />
        {current.matches("Review.userError") && <ErrorModal />}
      </Content>
    </>
  );
}

function ContactInfo({
  contactInfo,
}: {
  contactInfo: MachineContext["contactInfo"];
}) {
  return (
    <Content spacing="small">
      <Heading level={5}>{messages.contactInfoHeading.message}</Heading>
      <div className={styles.contactContainer}>
        <Text>{`${contactInfo.firstName} ${contactInfo.lastName}`}</Text>
        {contactInfo.companyName && <Text>{contactInfo.companyName}</Text>}
        <Text>{contactInfo.email}</Text>
        <Text>{contactInfo.phoneNumber}</Text>
      </div>
    </Content>
  );
}

function ServiceAddress({
  serviceAddress,
}: {
  serviceAddress: MachineContext["serviceAddress"];
}) {
  return (
    <Content spacing="small">
      <Heading level={5}>{messages.serviceAddressHeading.message}</Heading>
      <Text>{`${serviceAddress.street1}, ${
        serviceAddress.street2 ? `${serviceAddress.street2}, ` : ""
      }${serviceAddress.city}, ${serviceAddress.province} ${
        serviceAddress.postalCode
      }, ${serviceAddress.country}`}</Text>
    </Content>
  );
}

function JobDescription({
  jobDescription,
}: {
  jobDescription: string | undefined;
}) {
  return (
    <Content spacing="small">
      <Heading level={5}>{messages.jobDescriptionHeading.message}</Heading>
      <Text>{jobDescription ?? "—"}</Text>
    </Content>
  );
}

function AppointmentTime({
  appointment,
}: {
  appointment: Appointment | undefined;
}) {
  const { arrivalWindowDuration, arrivalWindowCentered } =
    useContext(ArrivalWindowContext);

  if (!appointment) {
    return <></>;
  }
  return (
    <Content spacing="small">
      <Heading level={5}>{messages.appointmentTimeHeading.message}</Heading>
      {appointmentTimeFormat(
        appointment.startTime,
        appointment.endTime,
        appointment.duration,
        arrivalWindowDuration,
        arrivalWindowCentered,
      )}
    </Content>
  );
}

// TODO: care about locale
function appointmentTimeFormat(
  start: string,
  end: string,
  duration: number,
  arrivalWindowDuration: number,
  arrivalWindowCentered: boolean,
) {
  const date = new Date(start).toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const startTime = new Date(start);
  const endTime = new Date(end);

  const formattedTimeRange = format.timeRange(
    startTime,
    endTime,
    arrivalWindowDuration,
    arrivalWindowCentered,
  );

  const showArrivalWindow = arrivalWindowDuration !== 0;
  const timeRangeString = showArrivalWindow
    ? `Arriving between ${formattedTimeRange}`
    : formattedTimeRange;

  return (
    <div className={styles.appointmentContainer}>
      <FormatDate date={date} />
      <Text>{timeRangeString}</Text>
      {showArrivalWindow && (
        <Text>Estimated duration: {minutesToDuration(duration)} </Text>
      )}
    </div>
  );
}

function ConfirmationMessage({
  confirmationMessage,
}: {
  confirmationMessage?: string;
}) {
  if (!confirmationMessage) {
    return null;
  }

  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      <Text>{confirmationMessage}</Text>
    </div>
  );
}
